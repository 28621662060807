import React from 'react';
import { MiddysSelectOption } from './components/middysFields/middysSelect/MiddysSelect';
import { routes } from './Routes';

export {};
export const SIZES = {
  xs: 0,
  sm: 560,
  md: 780,
  lg: 1024,
  xl: 1366,
};

export const AUSTRALIAN_STATES = [
  'New South Wales',
  'Queensland',
  'South Australia',
  'Tasmania',
  'Victoria',
  'Western Australia',
  'Australian Capital Territory',
  'Northern Territory',
];

export const STATE_OPTIONS: MiddysSelectOption[] = AUSTRALIAN_STATES.map((s) => ({
  label: s,
  value: s,
}));

export const TERMS_LINK =
  'https://media.middys.com.au/staging/middys/media/media/legaldocuments/privacy-policy.pdf';

export const TERMS_LABEL = 'By submitting this form, I agree to the ';

export const REGISTER_INTEREST_BANNER_FOOTER_ROUTES = [routes.packages];

export const MIDDYS_SOCIALS_LINKS = {
  FACEBOOK_LINK: 'https://www.facebook.com/MiddysElectrical',
  INSTAGRAM_LINK: 'https://www.instagram.com/Middyselectrical',
  LINKEDIN: 'https://www.linkedin.com/company/middyselectrical',
};

export const BACKGROUND_COVER_GENERIC_TEXT =
  "Transform your home with Middy's Home Services.\nConnecting you to the latest electrical products and services, fully installed by qualified licensed electricians in your area.";
