import { Close } from '@material-ui/icons';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import { Dialog, AppBar, Toolbar, makeStyles } from '@material-ui/core';
import React from 'react';
import { FC } from 'react';
import ContentContainer from '../../components/containers/ContentContainer';
import { theme } from '../../theme/Theme';
import QuoteForm, { QuoteFormProps } from './QuoteForm';
import { SIZES } from '../../Constants';

interface FullScreenQuoteFormDialogProps {
  onClose: () => void;
  quoteFormProps?: QuoteFormProps;
}

const useStyles = makeStyles({
  formWrapper: {
    flexGrow: 1,
    [`@media (min-width: ${SIZES.lg}px)`]: {
      marginLeft: theme.units.margin * 10,
    },
    [`@media (min-width: ${SIZES.md}px) and (max-width: ${SIZES.lg})px`]: {
      marginBottom: '2em',
    },
    display: 'flex',
  },
});

const FullScreenQuoteFormDialog: FC<FullScreenQuoteFormDialogProps> = ({
  onClose,
  quoteFormProps,
}) => {
  const styles = useStyles();
  return (
    <Dialog style={{ paddingTop: 40 }} open fullScreen>
      <AppBar style={{ background: theme.colors.secondary }}>
        <Toolbar>
          <ContentContainer
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              paddingRight: 0,
            }}
          >
            <Close
              onClick={() => {
                onClose();
              }}
              style={{ color: 'white', cursor: 'pointer' }}
            />
          </ContentContainer>
        </Toolbar>
      </AppBar>
      <div className={styles.formWrapper}>
        <QuoteForm fullScreen {...(quoteFormProps || {})} />
      </div>
    </Dialog>
  );
};

export default FullScreenQuoteFormDialog;
