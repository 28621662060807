import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useStyles } from './PriceTabStyles';
import { ListBox } from '../../../components/listBox/ListBox';

interface PriceTabProps {
  price: number;
  label: string;
  active?: boolean;
  onClick?: () => void;
}

const PriceTab: FC<PriceTabProps> = ({ price, label, active, onClick }) => {
  const styles = useStyles();
  return (
    <Box onClick={onClick} className={`${styles.container} ${active ? 'active' : ''}`}>
      <ListBox spacing={0.5}>
        <Typography color="textSecondary" variant="h6">
          {label}
        </Typography>
        <Typography className="title" color="secondary" variant="h2">
          ${price}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {'(exc GST)'}
        </Typography>
      </ListBox>
    </Box>
  );
};

export default PriceTab;
