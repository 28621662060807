import React, { FC } from 'react';
import { useStyles } from './MiddysFooterStyles';
import logoWhite from '../../assets/logos/middys-white.png';
import instagram from '../../assets/socials/instagram.png';
import facebook from '../../assets/socials/facebook.png';
import linkedIn from '../../assets/socials/linkedIn.png';
import sponsor1 from '../../assets/sponsors/sponsor1.png';
import sponsor2 from '../../assets/sponsors/sponsor2.png';
import sponsor3 from '../../assets/sponsors/sponsor3.png';
import sponsor4 from '../../assets/sponsors/sponsor4.png';
import poweredBy from '../../assets/poweredBy.png';
import {
  ABOUT_MIDDYS_LINKS,
  FOOTER_LINKS_PACKAGES,
  getPackageLabel,
  LABELS,
  NO_NEWSLETTER_PATHS,
  SERVICES,
} from './MiddysFooterConstants';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ContentContainer from '../containers/ContentContainer';
import { useHistory, useLocation, useParams } from 'react-router';
import { routes } from '../../Routes';
// import { PRIORITY_ORDER } from '../header/packagesMenu/PackagesMenuConstants';
import MiddysLink from '../link/MiddysLink';
import { flatten, groupBy } from 'lodash';
import { ListBox } from '../listBox/ListBox';
import { ExpandMore } from '@material-ui/icons';
import NewsLetterForm from '../../containers/customerLanding/newsLetterForm/NewsLetterForm';
import BundlesService from '../../services/bundles/BundlesService';
import { MIDDYS_SOCIALS_LINKS } from '../../Constants';
import { PackageCategory } from '../header/packagesMenu/PackagesMenuConstants';

interface MiddysFooterProps {}

const MiddysFooter: FC<MiddysFooterProps> = () => {
  const styles = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { bundles, loadingPackages } = BundlesService.useGetBundles(['packages']);
  const data = bundles && groupBy(bundles?.packages, (p) => p.category!.title);
  const path = useLocation();

  const renderPackages = (packageCategory: PackageCategory) => {
    return (
      <ListBox flexGrow={1} spacing={3}>
        <MiddysLink to={{ pathname: routes.packages, search: `?filter=${packageCategory}` }}>
          <Box display="flex" gridGap="0.5em">
            <Typography className={styles.packageCategory} variant="h5">
              {getPackageLabel(packageCategory)}
            </Typography>
          </Box>
        </MiddysLink>
        {data![packageCategory].map((item) => (
          <Box
            onClick={() => {
              history.push(`${routes.bundleDetails}?bundleId=${item.productId}`);
            }}
          >
            <Typography className={styles.packageName}>
              {item.packageName || item.standard.title}
            </Typography>
          </Box>
        ))}
      </ListBox>
    );
  };

  return (
    <div className={styles.footerContainer}>
      {!NO_NEWSLETTER_PATHS.includes(path.pathname) && (
        <ContentContainer>
          <Box mb={4}>
            <NewsLetterForm />
          </Box>
        </ContentContainer>
      )}

      <div className={styles.topFooter}>
        {loadingPackages ? null : (
          <div className={styles.footerContent}>
            <div className={styles.flexRow}>
              <img src={logoWhite} className={styles.middysLogoImg} alt="logo" />
            </div>
            <div className={styles.flexRow}>
              <img
                src={poweredBy}
                className={styles.poweredBy}
                alt="powered by sorted"
                onClick={() => window.open('https://www.sortedservices.com/', '_blank')}
              />
            </div>

            {/* Packages */}
            {isSmall ? (
              <Box mt={4}>
                {flatten(Object.values(FOOTER_LINKS_PACKAGES)).map((p) => (
                  <Accordion elevation={0} classes={{ root: styles.accordionRoot }}>
                    <AccordionSummary
                      expandIcon={<ExpandMore style={{ color: 'white' }} fontSize="large" />}
                      classes={{ root: styles.accordionSummaryRoot }}
                    >
                      <Box width="100%" display="flex" justifyContent="space-between">
                        <Typography variant="h4" className="title" style={{ color: 'white' }}>
                          {p}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ListBox spacing={2}>
                        {data![p].map((item) => (
                          <Box
                            onClick={() => {
                              history.push(`${routes.bundleDetails}?bundleId=${item.productId}`);
                            }}
                          >
                            <Typography className={styles.packageName}>
                              {item.packageName || item.standard.title}
                            </Typography>
                          </Box>
                        ))}
                      </ListBox>
                    </AccordionDetails>
                  </Accordion>
                ))}
                <Accordion elevation={0} classes={{ root: styles.accordionRoot }}>
                  <AccordionSummary
                    expandIcon={<ExpandMore style={{ color: 'white' }} fontSize="large" />}
                    classes={{ root: styles.accordionSummaryRoot }}
                  >
                    <Box width="100%" display="flex" justifyContent="space-between">
                      <Typography variant="h4" className="title" style={{ color: 'white' }}>
                        {getPackageLabel(PackageCategory.Services)}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ListBox spacing={2}>
                      {SERVICES.map((item) => (
                        <Box
                          onClick={() => {
                            history.push(`${item.route}`);
                          }}
                        >
                          <Typography className={styles.packageName}>{item.label}</Typography>
                        </Box>
                      ))}
                    </ListBox>
                  </AccordionDetails>
                </Accordion>
                <Accordion elevation={0} classes={{ root: styles.accordionRoot }}>
                  <AccordionSummary
                    expandIcon={<ExpandMore style={{ color: 'white' }} fontSize="large" />}
                    classes={{ root: styles.accordionSummaryRoot }}
                  >
                    <Box width="100%" display="flex" justifyContent="space-between">
                      <Typography variant="h4" className="title" style={{ color: 'white' }}>
                        {LABELS.ABOUT_MIDDYS}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ListBox spacing={2}>
                      {ABOUT_MIDDYS_LINKS.map((item) => (
                        <Box
                          onClick={() => {
                            history.push(`${item.route}`);
                          }}
                        >
                          <Typography className={styles.packageName}>{item.label}</Typography>
                        </Box>
                      ))}
                    </ListBox>
                  </AccordionDetails>
                </Accordion>
              </Box>
            ) : (
              <Box>
                <Box
                  mt={6}
                  width="100%"
                  alignItems="flex-start"
                  flexWrap="wrap"
                  style={{ rowGap: '2em' }}
                  className={styles.footerRow}
                >
                  <ListBox spacing={8}>
                    {FOOTER_LINKS_PACKAGES.COL_1.map((p) => (
                      <>{renderPackages(p)}</>
                    ))}
                  </ListBox>
                  <ListBox spacing={8}>
                    {FOOTER_LINKS_PACKAGES.COL_2.map((p) => (
                      <>{renderPackages(p)}</>
                    ))}
                  </ListBox>
                  <ListBox spacing={8}>
                    {FOOTER_LINKS_PACKAGES.COL_3.map((p) => (
                      <>{renderPackages(p)}</>
                    ))}
                  </ListBox>
                  <Box className={styles.packageDetailsContainer}>
                    <Typography className={styles.packageCategory} variant="h5">
                      {LABELS.ABOUT_MIDDYS}
                    </Typography>
                    {ABOUT_MIDDYS_LINKS.map((item) => (
                      <Box
                        onClick={() => {
                          history.push(`${item.route}`);
                        }}
                      >
                        <Typography className={styles.packageName}>{item.label}</Typography>
                      </Box>
                    ))}
                  </Box>
                  <Box className={styles.packageDetailsContainer}>
                    <Typography className={styles.packageCategory} variant="h5">
                      {getPackageLabel(PackageCategory.Services)}
                    </Typography>
                    {SERVICES.map((item) => (
                      <Box
                        onClick={() => {
                          history.push(`${item.route}`);
                        }}
                      >
                        <Typography className={styles.packageName}>{item.label}</Typography>
                      </Box>
                    ))}
                  </Box>
                  <Box display="flex">
                    <div className={styles.socialsContainer}>
                      <div className={styles.socialsTitle}>{LABELS.FOLLOW}</div>
                      <div className={styles.socialIcons}>
                        <img
                          onClick={() => window.open(MIDDYS_SOCIALS_LINKS.FACEBOOK_LINK, '_blank')}
                          src={facebook}
                          className={styles.socialIcon}
                          alt="facebook"
                        />
                        <img
                          onClick={() => window.open(MIDDYS_SOCIALS_LINKS.INSTAGRAM_LINK, '_blank')}
                          src={instagram}
                          className={styles.socialIcon}
                          alt="instagram"
                        />
                        <img
                          onClick={() => window.open(MIDDYS_SOCIALS_LINKS.LINKEDIN, '_blank')}
                          src={linkedIn}
                          className={styles.socialIcon}
                          alt="linkedin"
                        />
                      </div>
                    </div>
                  </Box>
                </Box>
              </Box>
            )}
          </div>
        )}
      </div>
      <div className={styles.bottomFooter}>
        <div className={styles.footerContent}>
          <div className={styles.flexRowCentered}>
            <div className={styles.sponsors}>
              <img src={sponsor1} style={{ width: 42 }} className={styles.sponsor} alt="sponsor" />
              <img src={sponsor2} style={{ width: 50 }} className={styles.sponsor} alt="sponsor" />
              <img src={sponsor3} style={{ width: 70 }} className={styles.sponsor} alt="sponsor" />
              <img src={sponsor4} style={{ width: 80 }} className={styles.sponsor} alt="sponsor" />
            </div>
            <div className={styles.cc}>{LABELS.CC}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiddysFooter;
