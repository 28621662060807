import { theme } from '../../theme/Theme';
import { makeStyles, Theme } from '@material-ui/core';
import { SIZES } from '../../Constants';
import { loadingAnimation } from '../../theme/GlobalStyles';

export const useStyles = makeStyles<Theme, any>({
  sectionContainer: (props) => ({
    backgroundColor: theme.colors.filterBackground,
    padding: theme.units.padding * 8,
    marginTop: props.disableTopMargin ? 0 : theme.units.margin * 16,
    marginBottom: theme.units.margin * 8,
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: theme.units.padding * 4,
    },
  }),
  sectionRow: {
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column',
    },
    flexWrap: 'wrap',
  },
  endRow: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column',
    },
  },
  sectionContent: {
    width: '100%',
    maxWidth: 600,
    marginRight: theme.units.margin * 8,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginRight: 0,
      maxWidth: '100%',
    },
  },
  sectionTitle: (props) => ({
    fontFamily: theme.font.family2,
    fontSize: theme.font.xxl,
    fontWeight: 500,
    color: props.headingPrimary ? theme.colors.primary : theme.colors.secondary,
    marginBottom: theme.units.margin * 4,
  }),
  listItem: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    color: theme.colors.black60,
    marginBottom: theme.units.margin * 2,
  },
  sectionBody: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    color: theme.colors.black60,
    lineHeight: 1.6,
    maxWidth: 450,
  },
  sectionImageContainer: {
    width: '100%',
    maxWidth: 400,
    marginLeft: theme.units.margin * 4,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: theme.units.margin * 6,
      marginLeft: 0,
      maxWidth: '100%',
    },
  },
  endImageContainer: {
    width: '100%',
    maxWidth: 480,
    marginRight: theme.units.margin * 12,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: theme.units.margin * 6,
      maxWidth: '100%',
      marginRight: 0,
    },
  },
  sectionImage: (props) => ({
    width: '100%',
    height: '100%',
    objectFit: props.imgFill ? 'fill' : 'cover',
    objectPosition: 'center',
    maxHeight: 500,
  }),
  sectionButton: {
    position: 'relative',
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.l,
    color: theme.colors.light,
    padding: theme.units.padding * 2,
    paddingLeft: 5,
    paddingRight: 5,
    minWidth: 140,
    width: 'max-content',
    backgroundColor: theme.colors.buttonPrimary,
    border: `${theme.colors.buttonPrimary} 4px solid`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: theme.units.margin * 4,
    },
  },
  // LoadingState
  loadingSectionTitle: {
    marginBottom: theme.units.margin * 4,
    width: 100,
    height: theme.units.iconHeight * 1.5,
    backgroundColor: theme.colors.light,
  },
  loadingSectionBody: {
    maxWidth: 450,
    width: '100%',
    height: 180,
    backgroundColor: theme.colors.light,
  },
  loadingSectionImage: {
    backgroundColor: theme.colors.light,
    width: '100%',
    height: 300,
  },
  loadingSectionButton: {
    width: 160,
    height: 40,
    backgroundColor: theme.colors.light,
    borderRadius: theme.units.borderRadius,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: theme.units.margin * 4,
    },
  },
  loadingSectionContainer: {
    ...loadingAnimation,
    padding: theme.units.padding * 8,
    marginTop: theme.units.margin * 16,
    marginBottom: theme.units.margin * 8,
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: theme.units.padding * 4,
    },
  },
});
