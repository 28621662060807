export const LABELS = {
  HERO_TITLE: 'Get a Free Solar and Battery Proposal',
  HERO_TEXT:
    "We'll organize proposals outlining investment, rebates available and payback periods for you from up to 3 EnergyTrust contractors that support your area.",
  GET_QUOTE: 'Get a FREE Quote',
  TITLE: 'EnergyTrust, saving you more',
  TEXT: "EnergyTrust ® developed by Middy's is a best in class multi-technology energy efficiency proposal software tool. A unique software program that generates energy project proposals specific to your individual needs and requirements.\nStart saving and embark on an energy efficiency journey with a trusted Middy's EnergyTrust contractor today.",
  HOW_IT_WORKS: 'How It Works',
  CHECK_PRODUCT: 'Check that this product is available in your area',
  ADD_ADDRESS2: 'Add address',
  BACK: 'Back',
  MANAGE_EVERYTHING_TITLE: "Why choose Middy's EnergyTrust",
};

export const STEPS = [
  {
    title: 'Submit Your Quote Request',
  },
  {
    title: 'EnergyTrust Gets to Work',
  },
  {
    title: 'Receive Solar Proposals',
  },
];

export const LIST = [
  'Combat rising power prices, repair poor quality power and eliminate additional charges',
  'Tailored solutions specifically designed to meet your requirements and budget',
  'Competitive finance available',
  'Payback, bill reduction, energy savings, and return on investment',
  'No obligation, 100% free service',
];
