export enum LogLevel {
  None,
  Errors,
  Debug,
  Trace,
}

//setting baseUrl
//if env vars have been set by envconsul it will use that otherwise it will use dev url
const BaseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://sorted-app-bff.uat.sortedservices.com/sorted'
    : 'https://sorted-app-bff.uat.sortedservices.com/sorted';

const AirTableBaseURL = 'https://api.airtable.com/v0/appFwHACMjEPtr8Ov';
export const tradesUIURL = BaseUrl.includes('prodau')
  ? 'https://trade-ui.prodau.sortedservices.com/#/signUp'
  : 'https://trade-ui.uat.sortedservices.com/#/signUp';

const logRocketGroup = BaseUrl.includes('prodau')
  ? '#{LOG_ROCKET_PROD}#'
  : (process.env.LOG_ROCKET_UAT as string);

export interface TestSettings {
  readonly testing: boolean;
  readonly logLevel: LogLevel;
}

export class AppSettings {
  public static readonly server = {
    baseUrl: BaseUrl, // DO NOT end with '/'!
    airTableBaseURL: AirTableBaseURL,
    tradesUIURL,
    mockServices: false, // test use mocks by using jest.mock(), this is for manual testing
  };

  public static readonly logging = {
    logLevel: LogLevel.Trace,
  };

  public static initTesting() {
    AppSettings.logging.logLevel = AppSettings.testSettings.logLevel;

    this.testSettings = {
      ...this.testSettings,
      testing: true,
    };
  }

  public static get currentTestSetting(): TestSettings {
    return AppSettings.testSettings;
  }

  private static testSettings: TestSettings = {
    testing: false,
    logLevel: LogLevel.Debug,
  };

  public static readonly logRocket = {
    group: logRocketGroup,
  };
}
