import { makeStyles, Theme } from '@material-ui/core';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../Constants';

interface StyleProps {
  fullScreen?: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>({
  formContainer: ({ fullScreen }) => ({
    boxShadow: '0px 4px 19px 0px #0000002B',
    padding: `40px 30px`,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      padding: `${theme.units.padding * 10}px ${theme.units.padding * 10}px`,
    },
    [`@media (min-width: ${SIZES.lg}px)`]: {
      marginLeft: theme.units.margin * 10,
    },
    background: 'white',
    width: '100%',
    maxWidth: fullScreen ? '100%' : 700,
  }),
  popupContainer: {
    backgroundColor: theme.colors.light,
    height: '100vh',
  },
  noAddressTitle: {
    color: theme.colors.secondary,
    fontWeight: 200,
    fontSize: theme.font.s,
    fontFamily: theme.font.family,
    marginTop: 5,
    cursor: 'pointer',
  },
  propertyContainer: {
    marginLeft: 12,
  },
  propertyHeading: {
    fontWeight: 500,
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
  },
  propertySubHeading: {
    fontWeight: 300,
    fontSize: theme.font.s,
    fontFamily: theme.font.family,
  },
  propertyListContainer: {
    marginTop: 24,
  },
  propertyTitle: {
    color: theme.colors.black87,
    fontWeight: 500,
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
  },
  propertySubTitle: {
    color: theme.colors.inputText,
    fontWeight: 200,
    fontSize: theme.font.s,
    fontFamily: theme.font.family,
    marginTop: 4,
  },
  addressCard: {
    display: 'flex',
    marginTop: 12,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colors.darkGrey,
    paddingTop: 12,
    paddingBottom: 12,
    alignItems: 'center',
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: theme.colors.secondary,
      color: theme.colors.light,
      '& .property-icon': {
        color: `white !important`,
      },
    },
    color: theme.colors.black87,
    justifyContent: 'space-between',
    flexDirection: 'row',
    cursor: 'pointer',
  },
  selectedAddressCard: {
    backgroundColor: theme.colors.secondary,
    color: theme.colors.light,
    '& .property-icon': {
      color: `white !important`,
    },
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 12,
  },
  locationIcon: {
    width: 24,
    height: 24,
  },
  checkIconStyle: {
    width: 24,
    height: 24,
  },
  checkContainer: {
    width: 32,
    height: 32,
    borderRadius: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.light,
    marginRight: 24,
  },
});
