export const routes = {
  packages: '/packages',
  home: '/',
  category: '/category',
  marketplace: '/marketplace',
  addDetails: '/addDetails',
  selectTrade: '/selectTrade',
  addAddress: '/addAddress',
  bookTime: '/bookslot',
  confirmDetails: '/confirmDetails',
  complete: '/complete',
  bundleDetails: '/bundleDetails',
  electricianQuote: '/electricianQuote',
  solarQuote: '/solarQuote',
  jobDetails: '/jobDetails',
  contactUs: '/contactUs',
  registerInterest: '/registerInterest',
  faqs: '/faqs',
};
