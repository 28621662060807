import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import { cardContainer, pageContainer, root } from '../../theme/GlobalStyles';

export const useStyles = makeStyles({
  root: {
    ...root,
    minHeight: '100vh',
    height: 'auto',
    paddingBottom: '2rem',
  },
  container: {
    ...pageContainer,
    maxWidth: 600,
  },
  roundCorner: {
    borderRadius: '8px !important',
  },
  subContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 12,
  },
  vertSpace: {
    margin: '2rem auto',
  },
  leftCard: {
    ...cardContainer,
    flex: 2,
    marginTop: '10vh',
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.units.margin * 2,
    marginBottom: theme.units.margin * 2,
  },
  iconStyle: {
    width: theme.units.iconWidth * 2.5,
    height: theme.units.iconHeight * 2.5,
  },
  headingStyle: {
    fontFamily: theme.font.family,
    fontSize: theme.font.xxl,
    color: theme.colors.black87,
    marginTop: theme.units.margin * 2,
    textAlign: 'center',
  },
  subHeadingStyle: {
    fontFamily: theme.font.family,
    fontWeight: 300,
    fontSize: theme.font.s,
    color: theme.colors.black60,
    marginTop: theme.units.margin * 4,
    textAlign: 'center',
    maxWidth: 400,
  },
  doneButtom: {
    height: 40,
    width: 168,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.buttonPrimary,
    color: theme.colors.light,
    borderRadius: theme.units.borderRadius / 2,
    marginTop: theme.units.margin * 8,
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    cursor: 'pointer',
  },
});
