import { Box, makeStyles, Typography } from '@material-ui/core';
import { ArrowDownward } from '@material-ui/icons';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import React from 'react';
import { FC } from 'react';
import { ListBox } from '../../components/listBox/ListBox';
import { LABELS } from './QuoteFormConstants';
import { theme } from '../../theme/Theme';

interface CollapsedFormButtonProps {
  onClick: () => void;
}

const useStyles = makeStyles({
  collapsedFormContainer: {
    marginTop: '2em',
    padding: '1em',
    background: 'white',
    cursor: 'pointer',
  },
  arrowContainer: {
    background: theme.colors.secondary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    width: 50,
    borderRadius: '50%',
  },
});

const CollapsedFormButton: FC<CollapsedFormButtonProps> = ({ onClick }) => {
  const styles = useStyles();
  return (
    <div onClick={onClick} className={styles.collapsedFormContainer}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <ListBox spacing={2}>
          <Typography className="title" variant="h3" color="secondary">
            {LABELS.getQuote}
          </Typography>
          <Typography color="textSecondary">{LABELS.tapHere}</Typography>
        </ListBox>
        <div className={styles.arrowContainer}>
          <ArrowDownward style={{ color: theme.colors.light, fontSize: 30 }} />
        </div>
      </Box>
    </div>
  );
};

export default CollapsedFormButton;
