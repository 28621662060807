import * as Yup from 'yup';
import { MiddysSelectOption } from '../../components/middysFields/middysSelect/MiddysSelect';
import { enumToOptions } from '../../helpers/helperFunctions';
import { AddressValues } from '../../helpers/GooglePlacesHelper';
import { Property } from '../../models/common/Property';

export const LABELS = {
  heading: 'Get a FREE Quote',
  subheading: 'Your Details',
  loadingProperties: 'Loooking for properties...',
  fetchingTrades: 'Finding trades',
  submit: 'Submit',
  submitting: 'Submitting',
  getQuote: 'Get a FREE Quote',
  tapHere: 'Tap here to get started',
};

export const FIELD_LABELS = {
  firstName: 'First Name',
  lastName: 'Last Name',
  mobile: 'Mobile',
  email: 'Email',
  address: 'Address',
  interestedIn: 'Interested In',
  notes: 'Notes (optional)',
  timeframe: 'Timeframe',
};

export const FIELDS = {
  firstName: 'firstName',
  lastName: 'lastName',
  mobile: 'mobile',
  email: 'email',
  address: 'address',
  interest: 'interest',
  notes: 'notes',
  timeframe: 'timeframe',
  userId: 'userId',
};

export enum TimeFrame {
  LessThanSixMonths = 'Less than 6 Months',
  SixToTwelveMonths = '6-12 Months',
  TwelveToTwentyFourMonths = '12-24 Months',
  TwoPlusYears = '2+ Years',
  Unsure = 'Unsure',
}

export const TIMEFRAME_OPTIONS = enumToOptions(TimeFrame);

export const VALIDATION_SCHEMA = (interestNotRequired: boolean) =>
  Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().required('Email is required'),
    mobile: Yup.string().required('Mobile is required'),
    interest: interestNotRequired
      ? Yup.string().nullable()
      : Yup.string().required('Please select at least one interest'),
    timeframe: Yup.string()
      .typeError('Please select a timeframe in which you expect the work to be done')
      .required('Please select a timeframe in which you expect the work to be done'),
    address: Yup.object()
      .test(
        'has-valid-address',
        'A valid address is required',
        (value) =>
          value && typeof value === 'object' && value.address && (value.address as any).postcode,
      )
      .typeError('A valid address is required'),
  });

export interface QuoteFormValues {
  firstName: string;
  lastName: string;
  email: string;
  address: { address: AddressValues & { propertyId?: string }; input: string } | null;
  interest: InterestOptionEnum | '';
  timeframe: null | string;
  userId?: number | null;
  mobile: string;
  propertyDetails?: Property;
  notes: string;
}

export const INITIAL_VALUES = (fixedInterest?: InterestOptionEnum): QuoteFormValues => ({
  firstName: '',
  lastName: '',
  email: '',
  address: null,
  interest: fixedInterest || '',
  timeframe: null,
  userId: null,
  mobile: '',
  notes: '',
});

export enum InterestOptionEnum {
  Fans = 'Fans',
  ElectricSwitchboard = 'Electric Switchboard',
  Lights = 'Lights',
  Solar = 'Solar',
  EVCharging = 'EV Charging',
  GeneralElectricServices = 'General Electric Services',
}

export const INTEREST_OPTIONS: MiddysSelectOption[] = [
  { label: InterestOptionEnum.Fans, value: InterestOptionEnum.Fans },
  { label: InterestOptionEnum.Lights, value: InterestOptionEnum.Lights },
  { label: InterestOptionEnum.EVCharging, value: InterestOptionEnum.EVCharging },
  { label: InterestOptionEnum.ElectricSwitchboard, value: InterestOptionEnum.ElectricSwitchboard },
  { label: InterestOptionEnum.Solar, value: InterestOptionEnum.Solar },
  {
    label: 'General Electric Services (Please Specify)',
    value: InterestOptionEnum.GeneralElectricServices,
  },
];
