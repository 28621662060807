import { theme } from '../../theme/Theme';
import { makeStyles, withStyles } from '@material-ui/core';
import { pageContainer, root, cardContainer } from '../../theme/GlobalStyles';
import { SIZES } from '../../Constants';
import { ToggleButtonGroup } from '@material-ui/lab';

export const useStyles = makeStyles({
  root: {
    ...root,
    backgroundColor: theme.colors.light,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    ...pageContainer,
    marginTop: 0,
    paddingBottom: 90,
  },
  headerContainer: {
    maxWidth: 450,
    marginTop: -140,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      marginTop: -170,
    },
  },
  headerTitle: {
    fontFamily: theme.font.family2,
    fontWeight: 500,
    fontSize: theme.font.xxxl,
    color: theme.colors.light,
    marginBottom: theme.units.margin * 4,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.xxl,
    },
  },
  headerSubtitle: {
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.l,
    color: theme.colors.light,
    marginBottom: theme.units.margin * 12,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.m,
      marginBottom: theme.units.margin * 8,
    },
  },
  headerButton: {
    position: 'relative',
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.l,
    color: theme.colors.light,
    padding: theme.units.padding * 2,
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 180,
    width: 'max-content',
    backgroundColor: theme.colors.buttonPrimary,
    border: `${theme.colors.buttonPrimary} 2px solid`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.m,
    },
  },
  introContainer: {
    backgroundColor: theme.colors.filterBackground,
    padding: theme.units.padding * 8,
    marginTop: theme.units.margin * 16,
    marginBottom: theme.units.margin * 8,
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: theme.units.padding * 4,
    },
  },
  introRow: {
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column',
    },
  },
  introContent: {
    width: '100%',
    maxWidth: 600,
    marginRight: theme.units.margin * 8,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginRight: 0,
      maxWidth: '100%',
    },
  },
  introTitle: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.xxl,
    fontWeight: 500,
    color: theme.colors.secondary,
    marginBottom: theme.units.margin * 4,
  },
  introBody: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    color: theme.colors.black60,
    lineHeight: 1.6,
    maxWidth: 450,
  },
  introImageContainer: {
    width: '100%',
    maxWidth: 400,
    marginLeft: theme.units.margin * 12,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: theme.units.margin * 6,
      marginLeft: 0,
    },
  },
  introImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  introButton: {
    position: 'relative',
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.l,
    color: theme.colors.light,
    padding: theme.units.padding * 2,
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 140,
    width: 'max-content',
    backgroundColor: theme.colors.buttonPrimary,
    border: `${theme.colors.buttonPrimary} 4px solid`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  howItWorksContainer: {
    paddingTop: theme.units.padding * 8,
    marginBottom: theme.units.margin * 8,
  },
  howItWorksTitle: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.xxl,
    textAlign: 'center',
    fontWeight: 500,
    color: theme.colors.secondary,
    marginBottom: theme.units.margin * 12,
  },
  howItWorksSteps: {
    display: 'grid',
    gridGap: theme.units.margin * 8,
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    [`@media (max-width: ${SIZES.md}px)`]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  howItWorksStep: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  step: {
    width: 70,
    height: 70,
    borderRadius: 5000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: theme.font.family,
    fontSize: theme.font.xl,
    fontWeight: 500,
    color: theme.colors.light,
    backgroundColor: theme.colors.buttonPrimary,
  },
  stepTitle: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.xl,
    fontWeight: 500,
    color: theme.colors.black87,
    marginBottom: theme.units.margin * 4,
    marginTop: theme.units.margin * 5,
    textAlign: 'center',
  },
  stepText: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    color: theme.colors.black60,
    textAlign: 'center',
    lineHeight: 1.6,
  },
  endContainer: {
    backgroundColor: theme.colors.filterBackground,
    padding: theme.units.padding * 8,
    marginTop: theme.units.margin * 16,
    marginBottom: theme.units.margin * 8,
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: theme.units.padding * 4,
    },
  },
  endRow: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column',
    },
  },
  endContent: {
    width: '100%',
    maxWidth: 600,
    marginRight: theme.units.margin * 8,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginRight: 0,
      maxWidth: '100%',
    },
  },
  endTitle: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.xxl,
    fontWeight: 500,
    color: theme.colors.secondary,
    marginBottom: theme.units.margin * 4,
  },
  endBody: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    color: theme.colors.black60,
    lineHeight: 1.6,
    maxWidth: 450,
  },
  endImageContainer: {
    width: '100%',
    maxWidth: 400,
    marginRight: theme.units.margin * 12,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: theme.units.margin * 6,
      marginRight: 0,
    },
  },
  endImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  addressFooter: {
    width: '100%',
    backgroundColor: theme.colors.secondary,
    paddingTop: theme.units.padding * 4,
    paddingBottom: theme.units.padding * 4,
    marginTop: theme.units.margin * 8,
  },
  addressFooterContent: {
    ...pageContainer,
    width: '100%',
  },
  addressRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  addressRowIcon: {
    height: 30,
    marginRight: theme.units.margin * 4,
  },
  addressRowTitle: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.l,
    color: theme.colors.light,
    marginRight: 'auto',
  },
  backButton: {
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    color: theme.colors.darkGrey,
    borderRadius: theme.units.borderRadius / 1.5,
    padding: theme.units.padding * 2,
    minWidth: 140,
    width: 'max-content',
    border: `${theme.colors.borderColor} 4px solid`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  addressButton: {
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    color: theme.colors.light,
    borderRadius: theme.units.borderRadius / 1.5,
    padding: theme.units.padding * 2,
    minWidth: 140,
    width: 'max-content',
    backgroundColor: theme.colors.buttonPrimary,
    border: `${theme.colors.buttonPrimary} 2px solid`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  footerContainer: {
    backgroundColor: theme.colors.light,
    paddingTop: theme.units.padding * 8,
    paddingBottom: theme.units.padding * 8,
  },
  footerContentWrapper: {
    ...pageContainer,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  sectionContainer: {
    marginBottom: 0,
    height: '100%',
    marginLeft: 0,
  },
  sectionImg: {
    maxWidth: 'unset',
    maxHeight: 350,
    objectPosition: '0 -40px',
    objectFit: 'cover',
  },
});
