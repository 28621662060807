export const validationRegex = {
  contact: /^(\+61|0)\d{9}$/,
};

export const validationErrorMessages = {
  contact: 'Phone format should be +61XXXXXXXXX or 0XXXXXXXXX',
};

export const isValidEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};
