import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import circleLeftArrow from '../../assets/circle-left-arrow.png';
import circleRightArrow from '../../assets/circle-right-arrow.png';
import homeIcon from '../../assets/home.png';
import Header from '../../components/header/Header';
import ContactUs from '../../assets/contact-us-cropped.png';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './BundleDetailsStyles';
import { BundlePackage, Inclusion } from '../../models/bundles/Bundles';
import { LABELS, STEPS, STEPS_SMALL, WHY_CHOOSE } from './BundleDetailsConstants';
import {
  AppBar,
  Box,
  Dialog,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { routes } from '../../Routes';
import { BookingActions } from '../../store/actions/BookingActions';
import HowItWorksSection from '../../components/howItWorksSection/HowItWorksSection';
import SectionContent from '../../components/sectionContent/SectionContent';
import { getQueryParams } from '../../helpers/QueryHelper';
import { BundlesActions, BundlesActionTypes } from '../../store/actions/BundlesActions';
import LoadingHero from './components/LoadingHero';
import LoadingIntro from './components/LoadingIntro';
import LoadingInclusions from './components/LoadingInclusions';
import LoadingExtraContent from './components/LoadingExtraContent';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import { UpdateOfferType } from '../../models/common/UpdateOfferTypeRequest';
import { replaceYoutubeLink } from './BundleDetailsUtil';
import { SIZES } from '../../Constants';
import { ListBox } from '../../components/listBox/ListBox';
import PriceTab from './components/PriceTab';
import ContentContainer from '../../components/containers/ContentContainer';
import QuoteForm from '../quoteForm/QuoteForm';
import PreviewCarousel from '../../components/previewCarousel/PreviewCarousel';
import whyChooseImage from '../../assets/middysAssets/electrician2.png';
import Carousel from 'react-elastic-carousel';
import { ArrowLeft, ArrowRight, ChevronLeft, ChevronRight, Close } from '@material-ui/icons';
import Button from '../../components/button/Button';
import { theme } from '../../theme/Theme';
import CrumbNav from '../../components/crumbNav/CrumbNav';
import { title } from 'process';
import { PackageCategory } from '../../components/header/packagesMenu/PackagesMenuConstants';

interface BundleDetailsProps extends RouteComponentProps {
  loadingBundles: boolean;
  bundles: BundlePackage[] | undefined;
  selectedBundle: BundlePackage | undefined;
  accessToken: string | boolean | null;
  getBundlesRequest: () => void;
  updateOfferType: (data: UpdateOfferType) => void;
  updateSelectedBundle: (data: BundlePackage) => void;
  updateSkills: (data: string[]) => void;
}

const BundleDetails: FC<BundleDetailsProps> = ({
  history,
  location,
  loadingBundles,
  bundles,
  accessToken,
  getBundlesRequest,
}) => {
  const upgradedURL = getQueryParams(location, 'upgraded');
  const bundleIdURL = getQueryParams(location, 'bundleId');
  const [upgraded, setUpgraded] = useState<boolean>(false);
  const [activeImage, setActiveImage] = useState<number>(0);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showInclusion, setShowInclusion] = useState<boolean | Inclusion>(false);
  const [inclusionIndex, setInclusionIndex] = useState<number>(0);
  const [inclusionsToShow, setInclusionsToShow] = useState<number>(4);
  const [activeTab, setActiveTab] = useState<'details' | 'specifications'>('details');
  const [collapsedQuoteForm, setCollapsedQuoteForm] = useState<boolean>(true);

  if (!bundleIdURL || (bundles && !bundles.find((b) => b.productId === Number(bundleIdURL)))) {
    history.replace({ pathname: routes.packages });
  }

  const selectedBundle = bundles?.find((b) => b.productId === Number(bundleIdURL));
  const content = upgraded ? selectedBundle?.upgraded : selectedBundle?.standard;

  const images = useMemo<string[]>(() => {
    setActiveImage(0);
    if (selectedBundle === undefined) return [];
    return upgraded
      ? [selectedBundle.upgraded!.heroImage, ...selectedBundle.upgraded!.additionalImages]
      : [selectedBundle.standard.heroImage, ...selectedBundle.standard.additionalImages];
  }, [upgraded, selectedBundle]);

  useEffect(() => {
    setUpgraded(!!selectedBundle?.defaultUpgraded);
    setActiveTab('details');
  }, [selectedBundle]);

  useEffect(() => {
    if (upgradedURL) {
      setUpgraded(upgradedURL === 'true');
    }
  }, [upgradedURL]);

  useEffect(() => {
    if (selectedBundle && selectedBundle.productId === Number(bundleIdURL)) return;
    if (bundleIdURL && accessToken) {
      if (bundles === undefined) {
        getBundlesRequest();
      }
    }
  }, [bundleIdURL, bundles, accessToken]);

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth <= SIZES.sm) {
        setInclusionsToShow(1);
      } else if (window.innerWidth <= SIZES.md) {
        setInclusionsToShow(2);
      } else if (window.innerWidth <= SIZES.lg) {
        setInclusionsToShow(3);
      } else {
        setInclusionsToShow(4);
      }
    };
    resize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const onNextImage = () => {
    setActiveImage(activeImage === images.length - 1 ? 0 : activeImage + 1);
  };

  const onPreviousImage = () => {
    setActiveImage(activeImage === 0 ? images.length - 1 : activeImage - 1);
  };

  const styles = useStyles();
  const muiTheme = useTheme();

  const quoteFormSmall = useMediaQuery(muiTheme.breakpoints.down('md'));
  const hasSpecs = content?.extraContent[0]?.specifications;
  const productDetails = content
    ? { title: content?.title, description: content?.description }
    : undefined;
  const isSmall = useMediaQuery(muiTheme.breakpoints.down('xs'));

  return (
    <div>
      <Header />
      <div className={styles.content}>
        <ContentContainer>
          {selectedBundle && (
            <Box mt={4}>
              <CrumbNav
                steps={[
                  {
                    label: selectedBundle?.category?.title || '',
                    path: `${routes.packages}?filter=${selectedBundle?.category?.title}`,
                  },
                  {
                    label: selectedBundle?.standard.title || '',
                  },
                ]}
              />
            </Box>
          )}

          {!selectedBundle || loadingBundles ? (
            <>
              <LoadingHero />
              <LoadingIntro />
              <LoadingInclusions />
              <LoadingExtraContent />
              <HowItWorksSection steps={STEPS} title={LABELS.HOW_IT_WORKS} loading />
              <SectionContent title={LABELS.WHY_CHOOSE} image={ContactUs} reversed loading>
                {WHY_CHOOSE.map((why) => (
                  <>
                    <div className={styles.stepTitle}>{why.title}</div>
                    <div className={styles.stepText}>{why.text}</div>
                  </>
                ))}
              </SectionContent>
            </>
          ) : (
            <>
              <div className={styles.heroImageContainer}>
                <div className={styles.heroImageWrapper}>
                  <img
                    src={images[activeImage]}
                    className={`${styles.heroImage} ${
                      [PackageCategory.Fans].includes(
                        selectedBundle.category?.title as PackageCategory,
                      )
                        ? 'top'
                        : ''
                    }`}
                    alt="hero"
                  />
                </div>

                {images.length > 1 && (
                  <div className={styles.arrowsContainer}>
                    <img
                      src={circleLeftArrow}
                      className={styles.arrow}
                      onClick={() => onPreviousImage()}
                      alt="arrow"
                    />
                    <img
                      src={circleRightArrow}
                      className={styles.arrow}
                      onClick={() => onNextImage()}
                      alt="arrow"
                    />
                  </div>
                )}
              </div>
              <div className={styles.bundleRow}>
                <div className={styles.bundleContent}>
                  <div className={styles.title}>{content!.title}</div>
                  <Box
                    display="flex"
                    flexDirection={!!selectedBundle?.defaultUpgraded ? 'row-reverse' : 'row'}
                    width="100%"
                    mb={1}
                  >
                    <PriceTab
                      price={selectedBundle.standard.price}
                      label={selectedBundle.standard.label || 'Standard'}
                      active={!upgraded}
                      onClick={() => setUpgraded(false)}
                    />
                    {!!selectedBundle.upgraded && (
                      <PriceTab
                        price={selectedBundle.upgraded!.price}
                        label={selectedBundle.upgraded!.label || 'Upgraded'}
                        active={upgraded}
                        onClick={() => setUpgraded(true)}
                      />
                    )}
                  </Box>
                  <div className={styles.howDoEstimates} onClick={() => setShowPopup(true)}>
                    {LABELS.HOW_DO_EST}
                  </div>

                  {quoteFormSmall && (
                    <Box my={2}>
                      <Button
                        handlePress={() => {
                          setCollapsedQuoteForm(false);
                        }}
                      >
                        {LABELS.GET_FREE_QUOTES}
                      </Button>
                    </Box>
                  )}

                  {quoteFormSmall && !collapsedQuoteForm && (
                    <Dialog style={{ paddingTop: 40 }} open fullScreen>
                      <AppBar style={{ background: theme.colors.secondary }}>
                        <Toolbar>
                          <ContentContainer
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              width: '100%',
                              paddingRight: 0,
                            }}
                          >
                            <Close
                              onClick={() => {
                                setCollapsedQuoteForm(true);
                              }}
                              style={{ color: 'white', cursor: 'pointer' }}
                            />
                          </ContentContainer>
                        </Toolbar>
                      </AppBar>
                      {productDetails && (
                        <div className={styles.formWrapper}>
                          <QuoteForm productDetails={productDetails} />
                        </div>
                      )}
                    </Dialog>
                  )}
                </div>
              </div>
            </>
          )}
        </ContentContainer>
        {hasSpecs && (
          <ContentContainer fullWidthOnSmallViewport>
            <Box display="flex" mt={4}>
              <Box
                onClick={() => setActiveTab('details')}
                className={`${styles.tab} ${activeTab === 'details' ? 'active' : ''}`}
              >
                <Typography variant="h6"> {LABELS.PRODUCT_DETAILS}</Typography>
              </Box>

              <Box
                onClick={() => setActiveTab('specifications')}
                className={`${styles.tab} ${activeTab === 'specifications' ? 'active' : ''}`}
              >
                <Typography variant="h6">{LABELS.SPECIFICATIONS}</Typography>
              </Box>
            </Box>
          </ContentContainer>
        )}
        <Box mt={hasSpecs ? 0 : 4} className={styles.greyBox}>
          <ContentContainer>
            <Box className={styles.bundleRow} style={{ marginTop: 16 }}>
              <ListBox spacing={2}>
                {!hasSpecs && (
                  <div className={styles.title}>
                    {activeTab === 'details' ? LABELS.PRODUCT_DETAILS : LABELS.SPECIFICATIONS}
                  </div>
                )}
                {activeTab === 'details' ? (
                  <Typography color="textSecondary">{content?.description}</Typography>
                ) : (
                  <div className={styles.specsGrid}>
                    {content?.extraContent[0]?.specifications?.map((s) => (
                      <>
                        <div className={styles.specKey}>{s.key}:</div>
                        <div className={styles.specValue}>{s.value}</div>
                      </>
                    ))}
                  </div>
                )}
              </ListBox>
              {!quoteFormSmall && productDetails && (
                <div className={`${styles.formContainer} ${!hasSpecs && 'noSpecs'}`}>
                  <QuoteForm productDetails={productDetails} containerStyles={{ maxWidth: 600 }} />
                </div>
              )}
            </Box>
            {content?.inclusions && (
              <Box mt={4}>
                <div className={styles.inclusionsContainer}>
                  <div className={styles.subheading}>
                    {upgraded
                      ? LABELS.UPGRADED_PACKAGE(selectedBundle!.upgraded?.label)
                      : LABELS.STANDARD_PACKAGE(selectedBundle!.standard.label)}
                  </div>
                  <Carousel
                    isRTL={false}
                    pagination={false}
                    itemsToShow={inclusionsToShow}
                    itemsToScroll={1}
                    itemPosition={'START'}
                    itemPadding={[0, 12]}
                    initialActiveIndex={inclusionIndex}
                    onChange={(currentItemObject: any) => {
                      setInclusionIndex(currentItemObject.index);
                    }}
                    showArrows={content!.inclusions.length !== 1}
                    renderArrow={({ type, onClick }) => {
                      const pointer =
                        type === 'PREV' ? (
                          <ChevronLeft className={styles.carouselArrow} />
                        ) : (
                          <ChevronRight className={styles.carouselArrow} />
                        );
                      return (
                        <div
                          onClick={onClick}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {pointer}
                        </div>
                      );
                    }}
                  >
                    {content!.inclusions.map((inclusion) => (
                      <div
                        onClick={() => setShowInclusion(inclusion)}
                        className={`${styles.inclusionContainer} ${
                          content!.inclusions.length > 1 && 'multiple'
                        }`}
                      >
                        <div className={styles.inclusionImageContainer}>
                          <img
                            className={styles.inclusionImage}
                            src={inclusion.image}
                            alt="inclusion"
                          />
                        </div>
                        <div className={styles.inclusionContentContainer}>
                          <div className={styles.inclusionTitle}>
                            {inclusion.name} x{inclusion.quantity}
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </div>
              </Box>
            )}
          </ContentContainer>
        </Box>
        <ContentContainer>
          <Box py={2}>
            <ListBox spacing={4}>
              <Typography color="textSecondary" style={{ fontSize: 12, fontStyle: 'italic' }}>
                {LABELS.DISCLAIMER}
              </Typography>
              <HowItWorksSection
                altStyle
                title={LABELS.HOW_IT_WORKS_TITLE}
                steps={isSmall ? STEPS_SMALL : STEPS}
              />
              {!!content?.video && (
                <iframe
                  src={replaceYoutubeLink(content.video)}
                  className={styles.iframe}
                  frameBorder="0"
                />
              )}

              <SectionContent
                title={LABELS.WHY_CHOOSE}
                image={whyChooseImage}
                reversed
                headingPrimary
                imgFill
              >
                <ListBox mt={4} spacing={6}>
                  {WHY_CHOOSE.map((why) => (
                    <ListBox spacing={2}>
                      <Typography variant="h3">{why.title}</Typography>
                      <Typography>{why.text}</Typography>
                    </ListBox>
                  ))}
                </ListBox>
              </SectionContent>
            </ListBox>
          </Box>
          <Dialog
            open={!!showInclusion}
            maxWidth="sm"
            fullWidth
            style={{
              zIndex: 999999,
            }}
            onClose={() => setShowInclusion(false)}
          >
            <div className={styles.modal}>
              <div className={styles.modalImageContainer}>
                <img
                  className={styles.modalImageContain}
                  src={!!showInclusion ? (showInclusion as Inclusion).image : ''}
                  alt="image"
                />
              </div>
              <div className={styles.modalContent}>
                <div className={styles.modalTitle}>
                  {!!showInclusion && (showInclusion as Inclusion).name}
                </div>
                <div className={styles.modalText}>
                  {!!showInclusion && (showInclusion as Inclusion).description}
                </div>
                <div className={styles.addressButton} onClick={() => setShowInclusion(false)}>
                  {LABELS.MODAL_BUTTON}
                </div>
              </div>
            </div>
          </Dialog>
        </ContentContainer>
      </div>
    </div>
  );
};

const loading = loadingSelector([BundlesActionTypes.GET_BUNDLES]);

const mapStateToProps = (state: ApplicationState) => ({
  loadingBundles: loading(state),
  bundles: state.bundlesState.bundles,
  accessToken: state.token.accessToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getBundlesRequest: () => dispatch(BundlesActions.getBundlesRequest()),
  updateOfferType: (data: UpdateOfferType) => dispatch(BookingActions.updateOfferType(data)),
  updateSkills: (data: string[]) => dispatch(BookingActions.updateSkills(data)),
  updateSelectedBundle: (data: BundlePackage) =>
    dispatch(BundlesActions.updateSelectedBundle(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BundleDetails));
