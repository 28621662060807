import { makeStyles } from '@material-ui/core';
import { theme } from '../../../theme/Theme';
import { SIZES } from '../../../Constants';

export const useStyles = makeStyles({
  container: {
    padding: theme.units.padding * 10,
    background: '#f5f5f5',
  },
  socialsContainer: {
    display: 'flex',
    flexDirection: 'column',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginTop: theme.units.margin * 8,
    },
  },
  socialsTitle: {
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
    color: theme.colors.light,
    fontWeight: 400,
  },
  socialIcons: {
    display: 'flex',
    marginTop: theme.units.margin * 3,
  },
  socialIcon: {
    height: 22,
    width: 22,
    objectFit: 'contain',
    marginRight: theme.units.margin * 4,
    cursor: 'pointer',
    filter: 'brightness(0.4)',
  },
  successMessageContainer: {
    textAlign: 'center',
    alignItems: 'center',
  },
  iconStyle: {
    width: theme.units.iconWidth * 2.5,
    height: theme.units.iconHeight * 2.5,
  },
  subscribeButton: {
    [`@media (max-width: ${SIZES.sm}px)`]: {
      width: '100%',
    },
  },
});
