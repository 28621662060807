/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { FC, useEffect, useLayoutEffect } from 'react';
// import './App.css';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Route, Switch, useLocation } from 'react-router-dom';
import { Dispatch } from 'redux';
import { ApplicationState } from './store/RootReducer';
import { TokenActions } from './store/actions/TokenActions';
import { getQueryParams } from './helpers/QueryHelper';
import { AuthDetail, saveAuthDetails } from './helpers/AuthHelper';
import { routes } from './Routes';
import LandingScreen from './containers/landingScreen/LandingScreen';
import AddDetails from './containers/addDetails/AddDetails';
import BookTrade from './containers/bookTrade/BookTrade';
import AddAddress from './containers/addAddress/AddAddress';
import BookTime from './containers/bookTime/BookTime';
import ConfirmDetails from './containers/confirmDetails/ConfirmDetails';
import CompleteScreen from './containers/completeScreen/CompleteScreen';
import Marketplace from './containers/marketplace/Marketplace';
import BundleDetails from './containers/bundleDetails/BundleDetails';
import SolarQuote from './containers/solarQuote/SolarQuote';
import Jobdetails from './containers/jobDetails/Jobdetails';
import ElectricianQuote from './containers/electricianQuote/ElectricianQuote';
import { sendMessageToApp, ValidMessages } from './helpers/MessageHelper';
import { BookingActions } from './store/actions/BookingActions';
import Spinner from './components/spinnerComponent/Spinner';
import { getPageTitle } from './helpers/AppNameHelper';
import { OfferType } from './models/common/OfferType';
import { Step } from './models/common/Step';
import { JobDetails } from './models/common/JobDetails';
import { UserDetails } from './models/auth/User';
import CategoryScreen from './containers/categoryScreen/CategoryScreen';
import CustomerLanding from './containers/customerLanding/CustomerLanding';
import { useStyles } from './AppStyles';
import RegisterInterests from './containers/registerInterest/RegisterInterest';
import ContactUs from './containers/contactUs/ContactUs';
import LogRocket from 'logrocket';
import { AppSettings } from './AppSettings';
import FAQs from './containers/faqs/FAQs';
import MiddysFooter from './components/middysFooter/MiddysFooter';

const clientToken = localStorage.getItem('state')
  ? JSON.parse(localStorage.getItem('state')!)?.token?.clientToken
  : undefined;

console.log('AppSettings.logRocket.group', AppSettings.logRocket.group);

if (AppSettings.logRocket.group && clientToken) {
  LogRocket.init(AppSettings.logRocket.group, {
    network: {
      requestSanitizer: (request) => {
        // Add sanitizer if needed
        return request;
      },
    },
  });

  LogRocket.identify(atob(clientToken).split(':')[0]);
}

interface AppProps extends RouteComponentProps {
  accessToken: string | boolean | null;
  saveTokenDetails: (accessToken: string, refreshToken: string, appName: string) => void;
  setExternal: (value: boolean) => void;
  registerClient: () => void;
  getProperty: (propertyId: string) => void;
  clientToken: string | boolean | null;
  getGuestToken: (data: string) => void;
  offerType: OfferType | undefined;
  steps: Step[] | undefined;
  jobDetails: JobDetails | undefined;
  userDetails: UserDetails | undefined;
  isExternal: boolean;
}

const App: FC<AppProps> = ({
  history,
  accessToken,
  location,
  saveTokenDetails,
  setExternal,
  registerClient,
  getProperty,
  clientToken,
  getGuestToken,
  offerType,
  steps,
  jobDetails,
  userDetails,
  isExternal,
}) => {
  const accessTokenURL = getQueryParams(location, 'accessToken');
  const refreshTokenURL = getQueryParams(location, 'refreshToken');
  const appNameURL = getQueryParams(location, 'appName');
  const isExternalURL = getQueryParams(location, 'isExternal');
  const propertyId = getQueryParams(location, 'propertyId');

  const classes = useStyles();

  const { pathname } = useLocation();

  useEffect(() => {
    if (accessTokenURL && refreshTokenURL && appNameURL) {
      saveTokenDetails(accessTokenURL, refreshTokenURL, appNameURL);
    }
  }, [accessTokenURL, refreshTokenURL, appNameURL]);

  useEffect(() => {
    if (isExternalURL) {
      setExternal(isExternalURL === 'true');
    } else {
      if (!clientToken) {
        registerClient();
      }
      if (!accessToken && clientToken) {
        getGuestToken(clientToken as string);
      }
    }
  }, [isExternalURL, clientToken]);

  useEffect(() => {
    if (isExternal) {
      if (offerType || offerType === OfferType.BUNDLE) {
        const stepIndex = steps ? steps.findIndex((data) => data.route === location.pathname) : -1;
        if (offerType === OfferType.SOLAR_FLOW_1 || offerType === OfferType.ELECTRICIAN_FLOW_1) {
          if (!jobDetails && steps && stepIndex > 0) {
            history.go(-stepIndex);
          }
          return;
        } else {
          if (!userDetails && steps && stepIndex > 0) {
            history.go(-stepIndex);
          }
          return;
        }
      }
    }
  }, [location.pathname, isExternal]);

  useEffect(() => {
    if (propertyId && isExternalURL && accessToken) {
      // call properyt api
      getProperty(propertyId);
    }
    const url = window.location.href;
    if (url.includes('prodau')) {
      window.location.href = url.replace('prodau.', '');
    }
  }, [propertyId, isExternalURL, accessToken]);

  useEffect(() => {
    sendMessageToApp(ValidMessages.token);
  }, []);

  useEffect(() => {
    document.addEventListener('message', (data) => {
      saveAuthDetails((data as unknown as AuthDetail).data);
    });
    window.addEventListener('message', (data) => {
      saveAuthDetails((data as unknown as AuthDetail).data);
    });

    return () => {
      document.removeEventListener('message', (data) => {
        saveAuthDetails((data as unknown as AuthDetail).data);
      });
      window.removeEventListener('message', (data) => {
        saveAuthDetails((data as unknown as AuthDetail).data);
      });
    };
  }, []);

  // In single page applications, if you change route while scrolled down
  // Scroll position persists, this fixes it by scrolling to top on path change
  useLayoutEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>{getPageTitle(location.pathname)}</title>
      </Helmet>
      {process.env.NODE_ENV !== 'production' && (
        <>
          <meta name="robots" content="noindex, nofollow" />
          <meta name="googlebot" content="noindex, nofollow" />
        </>
      )}
      <div>
        {accessToken ? (
          <>
            <Switch>
              <Route path={routes.packages} exact component={LandingScreen} />
              <Route path={routes.home} exact component={CustomerLanding} />
              <Route exact path={routes.addDetails} component={AddDetails} />
              <Route exact path={routes.selectTrade} component={BookTrade} />
              <Route exact path={routes.addAddress} component={AddAddress} />
              <Route exact path={routes.bookTime} component={BookTime} />
              <Route exact path={routes.confirmDetails} component={ConfirmDetails} />
              <Route exact path={routes.complete} component={CompleteScreen} />
              <Route path={routes.marketplace} exact component={Marketplace} />
              <Route path={routes.bundleDetails} exact component={BundleDetails} />
              <Route path={routes.solarQuote} exact component={SolarQuote} />
              <Route path={routes.jobDetails} exact component={Jobdetails} />
              <Route path={routes.electricianQuote} exact component={ElectricianQuote} />
              <Route path={routes.category} exact component={CategoryScreen} />
              <Route path={routes.registerInterest} exact component={RegisterInterests} />
              <Route path={routes.contactUs} exact component={ContactUs} />
              <Route path={routes.faqs} exact component={FAQs} />
            </Switch>
            <MiddysFooter
              {...(pathname === routes.packages ? { showRegisterInterestBanner: true } : {})}
            />
          </>
        ) : (
          <Spinner overrideLoading />
        )}
        <Spinner />
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  accessToken: state.token.accessToken,
  clientToken: state.token.clientToken,
  offerType: state.bookingState.offerType,
  steps: state.bookingState.steps,
  jobDetails: state.bookingState.jobDetails,
  userDetails: state.bookingState.userDetails,
  isExternal: state.token.isExternal,
  packages: state.bundlesState.bundles,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveTokenDetails: (accessToken: string, refreshToken: string, appName: string) => {
    dispatch(TokenActions.setAccessToken(accessToken));
    dispatch(TokenActions.setRefreshToken(refreshToken));
    dispatch(TokenActions.setAppName(appName));
  },
  setExternal: (value: boolean) => dispatch(TokenActions.setExternal(value)),
  registerClient: () => dispatch(TokenActions.registerClientRequest()),
  getProperty: (propertyId: string) => dispatch(BookingActions.getProperty(propertyId)),
  getGuestToken: (data: string) => dispatch(TokenActions.getGuestToken(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
