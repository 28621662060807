import { makeStyles } from '@material-ui/core';
import { theme } from '../../../theme/Theme';

export const useStyles = makeStyles({
  container: {
    padding: '0.7em',
    border: `2px solid ${theme.colors.filterBackground}`,
    cursor: 'pointer',
    '&.active': {
      backgroundColor: theme.colors.filterBackground,
    },
    flexGrow: 1,
    borderRadius: '4px 0 0 4px',
    '&:not(:first-of-type)': {
      paddingLeft: '2em',
      borderRadius: '0 4px 4px 0',
    },
  },
});
