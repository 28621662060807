import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import { MultipartFormData } from '../MultipartFormData.data';
import { TradeRequest } from '../../models/tradeRequest/TradeRequest';
import { PropertyRequest } from '../../models/propertyRequest/PropertyRequest';
import { Property } from '../../models/common/Property';
import { TradeResponse } from '../../models/tradeResponse/TradeResponse';
import { RegisterInterestFormValues } from '../../containers/registerInterestForm/RegisterInterestFormConstants';
import { QueryKey, useFetchQuery, useMutationQuery } from '../reactQuery/reactQuery';

export type RegisterFormType = 'registerInterest' | 'contactUs' | 'quoteForm';

export interface PropertiesForEmail {
  properties: Property[];
  userId: number;
}

export interface NewsLetterSubsciptionPayload {
  email: string;
  name: string;
  source: string;
  category?: string;
}

const bookingService = () => {
  const service: ApiService = new ApiService(ServiceType.booking);
  const propertyService: ApiService = new ApiService(ServiceType.property);
  function createMaintenance(data: MultipartFormData[]): Promise<any> | any {
    return service.postMultipart({}, data);
  }

  const useCreateMaintenance = () => {
    const { mutate, isLoading, isSuccess } = useMutationQuery({
      key: ['create-maintenance-request'],
      func: createMaintenance,
    });

    return {
      createMaintenanceRequest: mutate,
      creatingMaintenanceRequest: isLoading,
      createMaintenanceRequestSuccess: isSuccess,
    };
  };

  function getTrades(data: TradeRequest): Promise<TradeResponse[]> {
    return service.get({
      query: {
        postcode: data.postcode,
        categories: data.categories.join(','),
        privateName: data.privateName,
        propertyAddress: data.propertyAddress,
      },
      route: ['offices'],
    });
  }

  const useGetTrades = (key: QueryKey | null, onSuccess: (data: TradeResponse[]) => void) => {
    const {
      data: trades,
      isFetching: gettingTrades,
      refetch: getTradesForPostcode,
    } = useFetchQuery(key || ['get-trades'], getTrades, { enabled: !!key, onSuccess });

    return {
      trades,
      gettingTrades,
      getTradesForPostcode,
    };
  };

  function getProperties(data: PropertyRequest): Promise<PropertiesForEmail> {
    return propertyService.get({
      query: {
        email: data.email,
      },
      route: ['properties'],
    });
  }

  function getProperty(propertyId: string): Promise<Property> {
    return propertyService.get({
      query: {},
      route: ['properties', propertyId],
    });
  }

  function useRegisterInterest(type: RegisterFormType) {
    const { mutate, isLoading, isSuccess } = useMutationQuery<void, RegisterInterestFormValues>({
      key: ['register-interest'],
      func: ({ otherRoomPreference, ...data }) => {
        return service.post(
          { route: ['form-submission'] },
          {
            privateName: 'MIDDYS',
            email: data.email,
            formName: type,
            formData: { ...data, otherRoomPreference: otherRoomPreference || undefined },
          },
        );
      },
    });

    return { registerInterest: mutate, isLoading, isSuccess };
  }

  const useGetPropertiesFromEmail = (key: QueryKey) => {
    const { data, isFetching, isSuccess } = useFetchQuery(key, getProperties, {
      enabled: !!key[1],
    });

    return {
      propertiesForEmail: data,
      fetchingPropertiesForEmail: isFetching,
      getPropertiesForEmailSuccess: isSuccess,
    };
  };

  const useNewsLetterSubscribe = () => {
    const { mutate, isSuccess, isLoading, reset } = useMutationQuery<
      void,
      NewsLetterSubsciptionPayload
    >({
      key: ['subscribe-newsletter'],
      func: (params) => {
        return service.post({ route: ['newsletter'] }, params);
      },
    });

    return {
      subscribe: mutate,
      subscribeSuccess: isSuccess,
      subscribeLoading: isLoading,
      resetSubscribe: reset,
    };
  };

  return {
    createMaintenance,
    getTrades,
    getProperties,
    getProperty,
    useRegisterInterest,
    useGetPropertiesFromEmail,
    useGetTrades,
    useCreateMaintenance,
    useNewsLetterSubscribe,
  };
};

export default bookingService();
