import { makeStyles } from '@material-ui/core';
import { theme } from '../../../theme/Theme';
import { SIZES } from '../../../Constants';

export const useStyles = makeStyles({
  container: {
    [`@media (min-width: ${SIZES.sm}px)`]: {
      width: 'min(calc(100vw - 64px), 1200px)',
    },
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
  },
  closeMenuButton: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.5em',
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.secondary,
    },
  },
  packagesListContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    rowGap: '2em',
    flexWrap: 'wrap',
  },
  packageDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: '0.5em',
    '& > *': {
      cursor: 'pointer',
      '&:hover': {
        color: theme.colors.secondary,
      },
    },
  },
  packageColumn: {
    [`@media (max-width: 1123px) and (min-width: 840px)`]: {
      '&:nth-of-type(2)': { order: 2, flexBasis: '100%' }, // Target children inside the container
      '&:nth-of-type(3)': { order: 1 },
    },

    [`@media (max-width: 839px)`]: {
      '&:nth-of-type(2)': { order: 2, flexBasis: '100%' },
      '&:nth-of-type(3)': { order: 3, flexBasis: '100%' },
    },
  },
});
