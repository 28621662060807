import * as yup from 'yup';
import { enumToOptions } from '../../helpers/helperFunctions';
import { validationErrorMessages, validationRegex } from '../../helpers/validationHelpers';
import { RegisterFormType } from '../../services/booking/BookingService';

export const FIELDS = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  contact: 'contact',
  state: 'state',
  postcode: 'postcode',
  rooms: 'rooms',
  upgrades: 'upgrades',
  timeframe: 'timeframe',
  otherRoomPreference: 'otherRoomPreference',
};

export interface RegisterInterestFormValues {
  firstName: string;
  lastName: string;
  email: string;
  contact: string;
  state: string;
  postcode: string;
  rooms: string[];
  upgrades: string[];
  timeframe?: string;
  otherRoomPreference?: string;
}

export const INITIAL_VALUES = (type: RegisterFormType) => {
  const commonValues = {
    firstName: '',
    lastName: '',
    email: '',
    contact: '',
    state: '',
    postcode: '',
    rooms: [],
    upgrades: [],
    otherRoomPreference: '',
  };

  if (type === 'contactUs') {
    return commonValues;
  }

  return {
    ...commonValues,
    timeframe: '',
  };
};

export enum RoomTypes {
  Kitchen = 'Kitchen',
  Dining = 'Dining',
  Bathroom = 'Bathroom',
  Bedroom = 'Bedroom',
  Living = 'Living',
  Study = 'Study (Home office)',
  Outdoor = 'Outdoor',
}

export enum Upgrades {
  SmartHomeSolutions = 'Smart Home Solutions',
  SwitchboardUpgrade = 'Switchboard Upgrade',
  ElectricVehicleHomeCharger = 'Electric Vehicle Home Charger',
  SolarProposal = 'Solar Proposal',
}

export enum TimeFrame {
  LessThanSixMonths = 'Less than 6 Months',
  SixToTwelveMonths = '6-12 Months',
  TwelveToTwentyFourMonths = '12-24 Months',
  TwoPlusYears = '2+ Years',
  Unsure = 'Unsure',
}

export const LABELS = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  contact: 'Contact Number',
  state: 'State (where you live)',
  postcode: 'Postcode',
  rooms: 'Rooms Interested in (select all that apply)',
  otherRoom: 'Other (Please specify)',
  notes: 'Notes (Optional)',
  upgrades: 'Upgrade(s) Interested in (select all that apply)',
  timeframe: 'Timeframe of Project',
  termsLabel: 'I have read and accept the',
  submit: 'Submit',
};

export const VALIDATION_SCHEMA = yup.object().shape({
  state: yup.string().required('State is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().required('Email is required'),
  contact: yup
    .string()
    .required('Contact number is required')
    .matches(validationRegex.contact, validationErrorMessages.contact),
  postcode: yup.number().required('Postcode is required'),
});

export const TIMEFRAME_OPTIONS = enumToOptions(TimeFrame);
export const UPGRADE_OPTIONS = enumToOptions(Upgrades);
export const ROOM_TYPE_OPTIONS = enumToOptions(RoomTypes);
