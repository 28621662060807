import React, { FC, useEffect, useState, useMemo } from 'react';
import SectionContentLoading from './SectionContentLoading';
import { useStyles } from './SectionContentStyles';
import FormattedText from '../formattedText/FormattedText';

interface SectionContentProps {
  title: string;
  list?: string[];
  text?: string;
  image?: string;
  actionText?: string;
  reversed?: boolean;
  children?: React.ReactNode;
  loading?: boolean;
  onAction?: () => void;
  imgPosition?: string;
  headingPrimary?: boolean;
  imgFill?: boolean;
  disableTopMargin?: boolean;
  containerClass?: any;
  imgClass?: any;
}

const SectionContent: FC<SectionContentProps> = ({
  title,
  list,
  text,
  actionText,
  reversed,
  children,
  loading,
  onAction,
  imgPosition,
  headingPrimary,
  imgFill,
  disableTopMargin,
  image,
  containerClass,
  imgClass,
}) => {
  const styles = useStyles({ headingPrimary, imgFill, disableTopMargin });
  return (
    <>
      {loading ? (
        <SectionContentLoading reversed={reversed} actionText={actionText} />
      ) : (
        <div className={`${styles.sectionContainer} ${containerClass || ''}`}>
          <div className={reversed ? styles.endRow : styles.sectionRow}>
            <div className={styles.sectionContent}>
              <div className={styles.sectionTitle}>{title}</div>
              {list && (
                <ul>
                  {list.map((li, idx) => (
                    <li className={styles.listItem} key={idx}>
                      {li}
                    </li>
                  ))}
                </ul>
              )}
              {text && (
                <div className={styles.sectionBody}>
                  <FormattedText>{text}</FormattedText>
                </div>
              )}
              {!!children && children}
            </div>
            {image && (
              <div
                className={
                  reversed
                    ? `${styles.endImageContainer} ${imgClass || ''}`
                    : `${styles.sectionImageContainer} ${imgClass || ''}`
                }
              >
                <img
                  src={image}
                  className={`${styles.sectionImage} ${imgClass || ''}`}
                  style={imgPosition ? { objectPosition: imgPosition } : undefined}
                  alt="section image"
                />
              </div>
            )}
          </div>
          {!!actionText && (
            <div className={styles.sectionButton} onClick={() => onAction!()}>
              {actionText}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SectionContent;
