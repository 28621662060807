import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './HeaderStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { theme } from '../../theme/Theme';
import arrowBack from '../../assets/arrow-back.png';
import { routes } from '../../Routes';
import { OfferType } from '../../models/common/OfferType';
import { getBackgroundImage } from './HeaderUtil';
import { noBackArrow, noLogo, topBarLinks } from './HeaderConstants';
import { SIZES } from '../../Constants';
import { sendMessageToApp, ValidMessages } from '../../helpers/MessageHelper';
import {
  AppBar,
  Toolbar,
  Box,
  Menu,
  Typography,
  useMediaQuery,
  Theme,
  Dialog,
} from '@material-ui/core';
import { CloseOutlined, Menu as MenuIcon } from '@material-ui/icons';
import MiddysLink from '../link/MiddysLink';
import { PackagesMenu } from './packagesMenu/PackagesMenu';
import BundlesService from '../../services/bundles/BundlesService';
import { debounce } from 'lodash';
import json2mq from 'json2mq';
import { ListBox } from '../listBox/ListBox';

interface HeaderProps extends RouteComponentProps {
  hideBar?: boolean;
  expanded?: boolean;
  arrowText?: string;
  expandedTitle?: string;
  expandedSubtext?: string;
  offerType: OfferType | undefined;
  isExternal: boolean;
  overrideBack?: () => void;
}

const Header: FC<HeaderProps> = ({
  hideBar,
  expanded,
  arrowText,
  expandedTitle,
  expandedSubtext,
  offerType,
  history,
  match,
  isExternal,
  overrideBack,
}) => {
  const styles = useStyles({ expanded });
  const background = getBackgroundImage(offerType);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showSmallScreenNav, setShowSmallScreenNav] = useState<boolean>(false);
  const [showSmallPackages, setShowSmallPackages] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>();
  const { bundles, loadingPackages } = BundlesService.useGetBundles(['packages']);

  const isSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('xs');
  });

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const goBackHandler = () => {
    if (overrideBack) {
      overrideBack();
      return;
    }
    if (
      isExternal === false &&
      [routes.bundleDetails, routes.solarQuote, routes.electricianQuote].includes(match.url)
    ) {
      sendMessageToApp(ValidMessages.Dashboard);
    } else {
      history.goBack();
    }
  };

  const renderNav = useCallback(
    () => (
      <>
        {topBarLinks.map((link) =>
          link.label === 'Products' ? (
            <Typography
              onClick={(e) => {
                if (isSmall) {
                  setShowSmallPackages(true);
                  setShowSmallScreenNav(false);
                } else {
                  setAnchorEl(e.currentTarget);
                }
              }}
              className={styles.clickableText}
              style={{ color: 'white', fontWeight: 500 }}
            >
              {link.label}
            </Typography>
          ) : (
            <MiddysLink style={{ color: 'white', fontWeight: 500 }} to={link.target}>
              {link.label}
            </MiddysLink>
          ),
        )}
      </>
    ),
    [isSmall],
  );

  return (
    <div className={`${styles.headerContainer} ${!hideBar && !expanded && styles.stick}`}>
      {!hideBar && !expanded && (
        <div className={`${styles.expandedWrapper}`}>
          <div className={styles.headerBar}>
            <Box display="flex" alignItems="center" width="100%">
              {!noBackArrow.includes(match.url) && (
                <img
                  onClick={() => goBackHandler()}
                  src={arrowBack}
                  className={styles.arrowHeader}
                  alt="back-arrow"
                />
              )}
              {(screenWidth >= SIZES.md || noBackArrow.includes(match.url)) && (
                <>
                  {!noLogo.includes(match.url) && (
                    <img
                      src={theme.logo}
                      className={styles.logo}
                      onClick={() => history.push(routes.home)}
                      alt="logo"
                    />
                  )}
                </>
              )}

              {/* Nav on big screen when scrolled, sits next to logo */}

              {!isSmall && (
                <AppBar
                  style={{
                    flexGrow: 1,
                    background: 'transparent',
                    boxShadow: 'none',
                    width: 'auto',
                  }}
                  position="static"
                >
                  <Toolbar disableGutters classes={{ root: styles.toolbarInline }}>
                    {renderNav()}
                  </Toolbar>
                </AppBar>
              )}

              <Menu
                style={{ marginTop: '1em' }}
                open={!!anchorEl}
                onClose={() => {
                  setAnchorEl(undefined);
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <PackagesMenu
                  packages={bundles?.packages}
                  loading={loadingPackages}
                  onClose={() => {
                    setAnchorEl(undefined);
                  }}
                />
              </Menu>

              {/* Hamburger when small viewport */}
              {isSmall && (
                <Box
                  onClick={() => {
                    setShowSmallScreenNav((curr) => !curr);
                  }}
                  display="flex"
                  justifyContent="flex-end"
                  flexGrow={1}
                  color="white"
                  className={styles.pointer}
                >
                  {showSmallScreenNav ? (
                    <CloseOutlined color="inherit" />
                  ) : (
                    <MenuIcon color="inherit" />
                  )}
                </Box>
              )}
            </Box>
          </div>
        </div>
      )}

      {expanded && (
        <>
          <img src={background} className={styles.background} alt="background" />
          <div className={styles.darken} />
          <div className={styles.headerExpanded}>
            <div className={styles.expandedWrapper}>
              <div className={styles.arrowContainer}>
                <img
                  onClick={() => goBackHandler()}
                  src={arrowBack}
                  className={styles.arrow}
                  alt="back-arrow"
                />
                <div className={styles.arrowText}>{arrowText}</div>
              </div>
              <div className={styles.expandedTitle}>{expandedTitle}</div>
              <div className={styles.expandedSubtext}>{expandedSubtext}</div>
            </div>
            <div className={styles.expandedBehind} />
          </div>
        </>
      )}

      {showSmallScreenNav && !expanded && (
        <ListBox py={2} alignItems="center" spacing={4} width="100%">
          {renderNav()}
        </ListBox>
      )}

      {showSmallPackages && isSmall && (
        <Dialog open fullScreen PaperProps={{ className: styles.packagesDialog }}>
          <PackagesMenu
            packages={bundles?.packages}
            loading={loadingPackages}
            onClose={() => {
              setShowSmallPackages(false);
            }}
          />
        </Dialog>
      )}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isExternal: state.token.isExternal,
  offerType: state.bookingState.offerType,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
