import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import { pageContainer } from '../../theme/GlobalStyles';

export const useStyles = makeStyles({
  footerContainer: {
    height: 90,
    boxShadow: '0px 15px 20px #888',
    position: 'fixed',
    bottom: 0,
    width: '100vw',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: theme.colors.light,
    zIndex: 1000,
  },
  footerContentWrapper: {
    ...pageContainer,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  priceContainer: {},
  priceLabel: {
    fontFamily: theme.font.family,
    color: theme.colors.label,
    fontSize: theme.font.s,
  },
  priceWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 2,
  },
  price: {
    fontFamily: theme.font.family,
    color: theme.colors.secondary,
    fontSize: theme.font.xxxl,
  },
  decimal: {
    position: 'relative',
    top: 3,
    left: 1,
    fontFamily: theme.font.family,
    color: theme.colors.secondary,
    fontSize: theme.font.s,
  },
});
