import * as Yup from 'yup';

export const LABELS = {
  heading: 'Newsletter Subscription',
  subheading:
    "Sign up to recieve the latest news, products, and solutions from Middy's Home Services",
  buttonLabel: 'Subscribe',
  subscribing: 'Subscribing...',
  successHeading: 'Thank you for subscribing',
  successMessageLine1:
    "You will be among the first to hear about Middy's Home Services' latest news, promotions, events and more.",
  successMessageLine2:
    'To ensure you receive our latest communications in your inbox, please add hello@middys.com.au to your email address book.',
  successMessageLine3: 'Don’t forget to follow @middyselectrical on:',
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  email: Yup.string().required('Email is required'),
});

export const NEWSLETTER_FORM_FIELDS = {
  name: 'name',
  email: 'email',
};

export const NEWSLETTER_FORM_LABELS = {
  name: 'First Name',
  email: 'E-mail',
};
