import React, { FC, useEffect, useState } from 'react';
import { useStyles } from './QuoteFormStyles';
import { Box, Dialog, Typography } from '@material-ui/core';
import {
  FIELD_LABELS,
  FIELDS,
  INITIAL_VALUES,
  INTEREST_OPTIONS,
  InterestOptionEnum,
  LABELS,
  QuoteFormValues,
  TIMEFRAME_OPTIONS,
  VALIDATION_SCHEMA,
} from './QuoteFormConstants';
import { Field, Formik, FormikProps } from 'formik';
import MiddysTextField from '../../components/middysFields/MiddysTextField';
import MiddysSelect from '../../components/middysFields/middysSelect/MiddysSelect';
import FieldLabel from '../../components/fieldLabel/FieldLabel';
import { theme } from '../../theme/Theme';
import { ListBox } from '../../components/listBox/ListBox';
import Button from '../../components/button/Button';
import { TERMS_LABEL, TERMS_LINK } from '../../Constants';
import { GooglePlacesHelper } from '../../helpers/GooglePlacesHelper';
import { AddressValue } from '../../helpers/AddressValue.data';
import SlideLeft from '../../helpers/transitionsHelper/SlideLeft';
import AddressForm from '../addAddress/AddressForm';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/RootReducer';
import BookingService from '../../services/booking/BookingService';
import { isValidEmail } from '../../helpers/validationHelpers';
import { getAddressField, getPropertyDetails } from '../confirmDetails/ConfirmDetailsUtils';
import CheckIcon from '../../assets/check-blue.png';
import { Property } from '../../models/common/Property';
import { PlaceOutlined } from '@material-ui/icons';
import { TradeResponse } from '../../models/tradeResponse/TradeResponse';
import {
  getQueryKeyForTrades,
  convertFormDataToRequest,
  convertToRegisterInterestPayload,
} from './QuoteFormUtil';
import { routes } from '../../Routes';
import { useHistory } from 'react-router';

export interface QuoteFormProps {
  containerStyles?: React.CSSProperties;
  productDetails?: { title: string; description: string };
  fullScreen?: boolean;
  fixedInterest?: InterestOptionEnum;
}

const QuoteForm: FC<QuoteFormProps> = ({
  containerStyles,
  productDetails,
  fullScreen,
  fixedInterest,
}) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [submittedValues, setSubmittedValues] = useState<QuoteFormValues | null>(null);
  const [hideProperties, setHideProperties] = useState<boolean>(false);
  const {
    registerInterest,
    isLoading: registerInterestSubmitting,
    isSuccess: registerInterestSuccess,
  } = BookingService.useRegisterInterest('quoteForm');

  const styles = useStyles({ fullScreen });
  const history = useHistory();

  // API hooks
  const { propertiesForEmail } = BookingService.useGetPropertiesFromEmail([
    'get-properties-for-email',
    email ? { email } : '',
  ]);

  const { gettingTrades } = BookingService.useGetTrades(
    // In case of solar, trade is not required
    submittedValues && submittedValues.interest !== InterestOptionEnum.Solar
      ? ['get-trades', getQueryKeyForTrades(submittedValues)]
      : null,
    (data: TradeResponse[]) => {
      if (data?.length > 0) {
        const quoteRequest = convertFormDataToRequest(submittedValues!, data[0], productDetails);

        createMaintenanceRequest([
          {
            name: 'privateWorksRequest',
            content: JSON.stringify(quoteRequest),
          },
        ]);
      } else {
        const payload = convertToRegisterInterestPayload(submittedValues!);
        registerInterest(payload);
      }
    },
  );

  const { createMaintenanceRequest, creatingMaintenanceRequest, createMaintenanceRequestSuccess } =
    BookingService.useCreateMaintenance();

  // constants
  let addressInput: HTMLInputElement | undefined = undefined;
  let handleAddressChanged: (value: any) => void;
  const successRedirect = (type: 'quote' | 'register-interest' | 'solar') => {
    history.push(routes.complete, { type });
  };

  // effects
  useEffect(() => {
    console.log('addressInput', addressInput);
    if (addressInput)
      GooglePlacesHelper.createGooglePlacesAutoComplete(
        addressInput,
        (value) => handleAddressChanged && handleAddressChanged(value),
      );
  }, [addressInput]);

  useEffect(() => {
    if (submittedValues?.interest === InterestOptionEnum.Solar) {
      const data = convertFormDataToRequest(submittedValues);

      createMaintenanceRequest([
        {
          name: 'privateWorksRequest',
          content: JSON.stringify(data),
        },
      ]);
    }
  }, [submittedValues]);

  useEffect(() => {
    if (propertiesForEmail?.properties?.length === 1) {
      const singleProperty = propertiesForEmail.properties[0];

      formikRef.current?.setFieldValue(FIELDS.address, {
        inputValue: getAddressField(singleProperty),
        address: getPropertyDetails(singleProperty),
      });
    }

    if (propertiesForEmail?.userId) {
      formikRef.current?.setFieldValue(FIELDS.userId, propertiesForEmail?.userId);
    }
  }, [propertiesForEmail]);

  useEffect(() => {
    if (createMaintenanceRequestSuccess) {
      submittedValues?.interest === InterestOptionEnum.Solar
        ? successRedirect('solar')
        : successRedirect('quote');
    }
    if (registerInterestSuccess) {
      successRedirect('register-interest');
    }
  }, [createMaintenanceRequestSuccess, registerInterestSuccess]);

  const renderPropertyAddress = (property: Property) => {
    const { unitNumber, streetNumber, streetName, state, suburb, postcode } = property;
    const address1 = `${unitNumber ? `${unitNumber}/` : ''}${streetNumber || ''} ${streetName}`;
    const address2 = `${suburb}, ${state} ${postcode}`;
    return (
      <div className={styles.propertyContainer}>
        <div className={styles.propertyHeading}>{address1}</div>
        <div className={styles.propertySubHeading}>{address2}</div>
      </div>
    );
  };

  const formikRef = React.useRef<FormikProps<QuoteFormValues>>(null);

  return (
    <div className={styles.formContainer} style={{ ...containerStyles }}>
      <Box mb={3}>
        <Typography style={{ fontFamily: theme.font.family2 }} color="secondary" variant="h2">
          {LABELS.heading}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography style={{ fontFamily: theme.font.family2 }} color="secondary" variant="h4">
          {LABELS.subheading}
        </Typography>
      </Box>
      <Formik
        onSubmit={(values) => {
          setSubmittedValues(values);
        }}
        initialValues={INITIAL_VALUES(fixedInterest)}
        validationSchema={VALIDATION_SCHEMA(!!productDetails)}
        innerRef={formikRef}
      >
        {({
          setFieldValue,
          values: { address: addressValue, email: emailValue },
          handleSubmit,
        }) => {
          handleAddressChanged = (value: AddressValue) => {
            if (value.place) {
              const formattedAddress = GooglePlacesHelper.getAddressFeildsFromGoogleAPI(value);
              if (
                !(
                  formattedAddress.streetNumber &&
                  formattedAddress.streetName &&
                  formattedAddress.suburb
                )
              ) {
                setShowForm(true);
              }

              setFieldValue(FIELDS.address, {
                inputValue: getAddressField(formattedAddress as any),
                address: formattedAddress,
              });
            }
          };

          return (
            <>
              <ListBox spacing={2}>
                <ListBox direction="row" style={{ width: '100%' }} spacing={4}>
                  <MiddysTextField
                    required
                    name={FIELDS.firstName}
                    placeholder={FIELD_LABELS.firstName}
                    label={FIELD_LABELS.firstName}
                  />
                  <MiddysTextField
                    required
                    name={FIELDS.lastName}
                    placeholder={FIELD_LABELS.lastName}
                    label={FIELD_LABELS.lastName}
                  />
                </ListBox>
                <ListBox direction="row" style={{ width: '100%' }} spacing={4}>
                  <MiddysTextField
                    required
                    name={FIELDS.mobile}
                    placeholder="0000 000 000"
                    label={FIELD_LABELS.mobile}
                  />
                  <MiddysTextField
                    required
                    name={FIELDS.email}
                    placeholder={FIELD_LABELS.email}
                    label={FIELD_LABELS.email}
                    onBlur={() => {
                      if (isValidEmail(emailValue.trim())) {
                        setEmail(emailValue.trim());
                      }
                    }}
                  />
                </ListBox>

                {/* Properties found */}
                {propertiesForEmail?.properties &&
                  propertiesForEmail.properties.length > 1 &&
                  !hideProperties && (
                    <div className={styles.propertyListContainer}>
                      <div className={styles.propertyTitle}>We found property matches for you!</div>
                      <div className={styles.propertySubTitle}>
                        Select a property from the list below or add a new one by searching below.
                      </div>
                      <div>
                        {propertiesForEmail.properties.map((property, index) => (
                          <div
                            className={`${styles.addressCard} ${
                              addressValue && addressValue.address?.propertyId === property.id
                                ? styles.selectedAddressCard
                                : undefined
                            }`}
                            onClick={() => {
                              setFieldValue(FIELDS.address, {
                                inputValue: getAddressField(property),
                                address: getPropertyDetails(property),
                              });
                              setHideProperties(true);
                            }}
                          >
                            <div className={styles.imageContainer}>
                              <PlaceOutlined
                                style={{ color: theme.colors.secondary }}
                                className="property-icon"
                              />
                              {renderPropertyAddress(property)}
                            </div>
                            {addressValue && addressValue.address?.propertyId === property.id && (
                              <div className={styles.checkContainer}>
                                <img
                                  src={CheckIcon}
                                  className={styles.checkIconStyle}
                                  alt="check"
                                />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                <MiddysTextField
                  required
                  name={FIELDS.address}
                  placeholder={FIELD_LABELS.address}
                  label={FIELD_LABELS.address}
                  inputRef={(elem: HTMLInputElement) => (addressInput = elem)}
                  onChange={(e: React.ChangeEvent) => {
                    setFieldValue(FIELDS.address, {
                      inputValue: (e.target as HTMLInputElement).value,
                      address: null,
                    });
                  }}
                  value={
                    addressValue?.address
                      ? getAddressField(addressValue.address)
                      : addressValue?.input
                  }
                />
                <div className={styles.noAddressTitle} onClick={() => setShowForm(true)}>
                  Can't find your address?
                </div>
                <ListBox direction="row" style={{ width: '100%' }} spacing={4}>
                  {!productDetails && !fixedInterest && (
                    <div style={{ flexBasis: '50%' }}>
                      <FieldLabel required label={FIELD_LABELS.interestedIn} />
                      <Field
                        placeholder="Interest"
                        name={FIELDS.interest}
                        options={INTEREST_OPTIONS}
                        component={MiddysSelect}
                      />
                    </div>
                  )}
                  <div style={{ flexBasis: productDetails || fixedInterest ? '100%' : '50%' }}>
                    <FieldLabel required label={FIELD_LABELS.timeframe} />
                    <Field
                      placeholder="Timeframe"
                      name={FIELDS.timeframe}
                      options={TIMEFRAME_OPTIONS}
                      component={MiddysSelect}
                    />
                  </div>
                </ListBox>
                <MiddysTextField
                  placeholder="Specific products/packages"
                  name={FIELDS.notes}
                  label={FIELD_LABELS.notes}
                />
                <ListBox mt={2} spacing={4} alignItems="flex-end">
                  <Box>
                    <Button
                      disabled={gettingTrades || creatingMaintenanceRequest}
                      handlePress={handleSubmit}
                    >
                      {gettingTrades
                        ? LABELS.fetchingTrades
                        : creatingMaintenanceRequest || registerInterestSubmitting
                        ? LABELS.submitting
                        : LABELS.submit}
                    </Button>
                  </Box>
                  <Typography color="textSecondary" variant="h6">
                    {TERMS_LABEL}
                    <a
                      style={{ color: theme.colors.secondary }}
                      target="_blank"
                      href={TERMS_LINK}
                      rel="noopener noreferrer"
                    >
                      <span color={theme.colors.textSecondary}>
                        <u>Middy's Privacy Policy</u>
                      </span>
                    </a>
                  </Typography>
                </ListBox>
              </ListBox>
              {showForm && (
                <Dialog open fullScreen style={{ zIndex: 999999 }} TransitionComponent={SlideLeft}>
                  <div className={styles.popupContainer}>
                    <AddressForm
                      closePopup={setShowForm}
                      address={addressValue && addressValue.address}
                      goNext={(values) => {
                        setShowForm(false);
                        setFieldValue(FIELDS.address, {
                          inputValue: getAddressField(values),
                          address: values,
                        });
                      }}
                      nextLabel="Done"
                    />
                  </div>
                </Dialog>
              )}
            </>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  selectedProperty: state.bookingState.selectedProperty,
});

export default connect(mapStateToProps, null)(QuoteForm);
