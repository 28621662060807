import { styled, Theme } from '@material-ui/core';
import { pageContainer } from '../../theme/GlobalStyles';
import { breakpoints } from '@material-ui/system';
import { SIZES } from '../../Constants';

interface ContentContainerProps {
  fullWidthOnSmallViewport?: boolean;
}

const getConditionalStyles = (props: ContentContainerProps) => ({
  [`@media (max-width: ${SIZES.md}px)`]: {
    maxWidth: props.fullWidthOnSmallViewport ? '100%' : '90%',
    ...(props.fullWidthOnSmallViewport ? { padding: 0 } : {}),
  },
});

export default styled('div')<Theme, ContentContainerProps>(({ fullWidthOnSmallViewport }) => ({
  ...pageContainer,
  ...getConditionalStyles({ fullWidthOnSmallViewport }),
}));
