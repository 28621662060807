import React, { useRef } from 'react';
import { Field, FieldProps } from 'formik';
import { styled, Theme } from '@material-ui/core';
import { theme } from '../../theme/Theme';
import MiddysErrorMessage from './MiddysErrorMessage';
import FieldLabel from '../fieldLabel/FieldLabel';

interface MiddysTextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  name: string;
  required?: boolean;
  altStyle?: boolean;
  inputRef?: React.Ref<HTMLTextAreaElement>;
}

const StyledTextArea = styled('textarea')(
  ({ altStyle, theme: _theme }: { altStyle?: boolean; theme: Theme }) => ({
    background: altStyle ? 'white' : theme.colors.inputBackground,
    padding: 12,
    outline: 'none',
    border: 'none',
    fontSize: theme.font.m,
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: 4,
    resize: 'none',
  }),
);

const MiddysTextArea: React.FC<MiddysTextAreaProps> = ({
  label,
  required,
  name,
  altStyle,
  inputRef,
  ...props
}) => (
  <div style={{ width: '100%' }}>
    {label && <FieldLabel required={required} label={label} />}
    <Field name={name}>
      {({ field }: FieldProps) => (
        <StyledTextArea {...field} {...props} altStyle={altStyle} ref={inputRef} />
      )}
    </Field>
    <MiddysErrorMessage style={{ marginTop: 5 }} name={name} />
  </div>
);

export default MiddysTextArea;
