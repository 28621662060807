import { routes } from '../../Routes';

export const LABELS = {};

export const noBackArrow = ['/', routes.packages, routes.complete];

export const noLogo = [routes.complete];

export const topBarLinks = [
  {
    label: 'Products',
    target: routes.packages,
  },
  {
    label: 'FAQs',
    target: routes.faqs,
  },
];
