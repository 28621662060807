import { AddressValues } from '../../helpers/GooglePlacesHelper';
import { UserDetails } from '../../models/auth/User';
import { Bundle, BundlePackage } from '../../models/bundles/Bundles';
import { JobDetails } from '../../models/common/JobDetails';
import { OfferType } from '../../models/common/OfferType';
import { Property } from '../../models/common/Property';
import { MaintenanceRequest } from '../../models/maintenanceRequest/MaintenanceRequest';
import { TradeResponse } from '../../models/tradeResponse/TradeResponse';
import { BookingState } from '../../store/state/BookingState';
import { BundlesState } from '../../store/state/BundlesState';
import { store } from '../../store/Store';

export const getRequest = (booking: BookingState, bundles: BundlesState): MaintenanceRequest => {
  const { userId } = store.getState().token;
  const {
    userDetails,
    jobDetails,
    preferredTime,
    selectedTrade,
    selectedProperty,
    offerType,
    tradeComment,
    skills,
    ageOfHouse,
    typeOfWalls,
    isParkingAvailable,
    hasPets,
    typeOfRoof,
    numberOfStories,
  } = booking;
  const standardBundle = bundles.selectedBundle ? bundles.selectedBundle.standard : null;
  const bundle = bundles.selectedBundle
    ? bundles.selectedBundle.selectedUpgraded
      ? bundles.selectedBundle.upgraded!
      : bundles.selectedBundle.standard
    : null;
  const request: MaintenanceRequest = {
    userId: userId ? (userId as string) : null,
    userCreateRequest: getUserDetails(userDetails),
    propertyRequest: getPropertyDetails(selectedProperty),
    details: jobDetails
      ? {
          title: jobDetails.title || '',
          description: jobDetails.description || '',
          preferredAvailability: {
            timeSlot: 'Other',
            otherTime: preferredTime || '',
          },
          propertyAccess: {
            'propertyAccessType': 'OWNER_OCCUPIED',
          },
          unSupervisedMinors: false,
          extendedData: getExtendedData(jobDetails, null, ageOfHouse, typeOfWalls, typeOfRoof),
          ...(hasPets ? { pet: { present: true } } : {}),
          ...(numberOfStories ? { stairs: { present: true, description: numberOfStories } } : {}),
          ...(isParkingAvailable ? { parkingAvailability: true } : {}),
        }
      : bundle
      ? {
          title: standardBundle
            ? bundles.selectedBundle && bundles.selectedBundle.selectedUpgraded
              ? standardBundle.title + ' ' + 'Upgrade'
              : standardBundle.title
            : '',
          description: bundle.description,
          preferredAvailability: {
            timeSlot: 'Other',
            otherTime: preferredTime || '',
          },
          propertyAccess: {
            'propertyAccessType': 'OWNER_OCCUPIED',
          },
          unSupervisedMinors: false,
          extendedData: getExtendedData(jobDetails, bundle, ageOfHouse, typeOfWalls, typeOfRoof),
          ...(hasPets ? { pet: { present: true } } : {}),
          ...(numberOfStories ? { stairs: { present: true, description: numberOfStories } } : {}),
          ...(isParkingAvailable ? { parkingAvailability: true } : {}),
        }
      : null,
    workOrderRequest:
      selectedTrade && selectedTrade.length
        ? getWorkOrderRequest(
            jobDetails && jobDetails.description
              ? jobDetails.description
              : bundle
              ? bundle.description
              : '',
            selectedTrade,
            tradeComment,
            skills,
          )
        : null,
    privateWorksDetails: getPrivateWorksRequest(offerType as OfferType),
    location: jobDetails && jobDetails.location ? jobDetails.location : undefined,
    price: offerType === OfferType.BUNDLE && bundle ? bundle.price : undefined,
    lineAmountType: 'exclusive',
    specificMaterials:
      offerType === OfferType.BUNDLE
        ? bundle && bundle.inclusions && bundle.inclusions.length > 0
          ? bundle.inclusions.map((item) => ({
              description: `${item.name} x${item.quantity}`,
              charge: 0,
              includedMaterial: true,
            }))
          : []
        : [],
  };
  return request;
};

export const getExtendedData = (
  jobDetails: JobDetails | undefined,
  bundle: Bundle | null,
  ageOfHouse?: string,
  typeOfWalls?: string,
  typeOfRoof?: string,
) => {
  let extendedData = {};
  if (jobDetails && jobDetails.quoteType) {
    extendedData = {
      ...extendedData,
      quoteType: jobDetails.quoteType,
    };
  }
  if (jobDetails && jobDetails.mostImportant) {
    extendedData = {
      ...extendedData,
      interestedQuoteType: jobDetails.mostImportant,
    };
  }
  if (bundle && bundle.inclusions) {
    extendedData = {
      ...extendedData,
      inclusions: bundle.inclusions,
    };
  }

  if (ageOfHouse) {
    extendedData = {
      ...extendedData,
      ageOfHouse,
    };
  }

  if (typeOfWalls) {
    extendedData = {
      ...extendedData,
      typeOfWalls,
    };
  }

  if (typeOfRoof) {
    extendedData = {
      ...extendedData,
      typeOfRoof,
    };
  }

  return extendedData;
};

export const getUserDetails = (userDetails?: UserDetails) => {
  return userDetails
    ? {
        name: userDetails.firstName,
        surname: userDetails.lastName,
        phone: userDetails.mobile,
        email: userDetails.email,
      }
    : null;
};

export const getPropertyDetails = (property?: Property) => {
  return property
    ? {
        propertyId: property.id,
        address: getAddressField(property),
        streetNumber: property.streetNumber,
        unitNumber: property.unitNumber,
        streetName: property.streetName,
        suburb: property.suburb,
        postcode: property.postcode,
        state: property.state,
        country: property.country,
      }
    : null;
};

export const getAddressField = (property: AddressValues) => {
  const unitAndStreetNumberField = `${property.unitNumber ? property.unitNumber + '/' : ''}${
    property.streetNumber
  }`;
  return `${unitAndStreetNumberField} ${property.streetName}, ${property.suburb} ${property.state} ${property.postcode}, ${property.country}`;
};

export const getWorkOrderRequest = (
  description: string,
  trade: TradeResponse[],
  tradeComment: string | undefined,
  skills: string[],
) => {
  return {
    description,
    category: skills.toString(), // add skills here
    propertyAccess: {
      'propertyAccessType': 'OWNER_OCCUPIED',
    },
    officeIds: getOfficeIds(trade),
    comments: tradeComment || '',
  };
};

export const getOfficeIds = (trade: TradeResponse[]) => {
  return trade.map((data) => data.id);
};

export const getPrivateWorksRequest = (offerType: OfferType) => {
  return {
    isPrivate: true,
    privateName: 'MIDDYS',
    workflowType: getWorkFlowType(offerType),
  };
};

export const getWorkFlowType = (offerType: OfferType) => {
  switch (offerType) {
    case OfferType.BUNDLE:
      return 'PACKAGE';
    case OfferType.ELECTRICIAN_FLOW_1:
      return 'ELECTRICITY';
    case OfferType.SOLAR_FLOW_1:
      return 'SOLAR';
    default:
      return 'PACKAGE';
  }
};
