import { routes } from '../../Routes';
import { PackageCategory } from '../header/packagesMenu/PackagesMenuConstants';

export const LABELS = {
  FOLLOW: 'Follow @middyselectrical on:',
  CC: '© 2025 Middendorp Electric Co PTY LTD. ABN 49 338 705 390',
  INTERESTED_TITLE: 'Interested in joining our network?',
  INTERESTED_TEXT: 'You can view details about our MyHome Services program',
  REGISTER_INTEREST_BANNER_TEXT:
    'Want to know when Middy’s Home Services are available in your area?',
  REGISTER_INTEREST: 'Register interest',
  ABOUT_MIDDYS: "About Middy's Home Services",
  SERVICES: 'Services',
};

export const SERVICES = [
  { label: 'Book An Electrician', route: routes.electricianQuote },
  { label: 'Solar Proposal', route: routes.solarQuote },
];

export const ABOUT_MIDDYS_LINKS = [
  {
    label: 'Contact Us',
    route: routes.contactUs,
  },
  {
    label: 'FAQs',
    route: routes.faqs,
  },
  {
    label: 'Register Interest',
    route: routes.registerInterest,
  },
];

export const FOOTER_LINKS_PACKAGES = {
  COL_1: [PackageCategory.Fans],
  COL_2: [PackageCategory.Lights, PackageCategory.EVCharging, PackageCategory.SwitchboardUpgrades],
  COL_3: [PackageCategory.ElectricalPackagesByRoom],
};

export const getPackageLabel = (packageCategory: PackageCategory) => {
  switch (packageCategory) {
    case PackageCategory.ElectricalPackagesByRoom:
      return 'Electrical Solutions by Room';

    case PackageCategory.EVCharging:
      return 'EV Charging';

    case PackageCategory.Lights:
      return 'Lights';

    case PackageCategory.Fans:
      return 'Fans';

    case PackageCategory.Services:
      return 'Electrical Services';

    case PackageCategory.SwitchboardUpgrades:
      return 'Switchboard Upgrades';

    default:
      return '';
  }
};

export const NO_NEWSLETTER_PATHS = [routes.complete];
