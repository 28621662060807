import { Grid, Typography, Box } from '@material-ui/core';
import React, { FC } from 'react';
import ContentContainer from '../../components/containers/ContentContainer';
import PageContainer from '../../components/containers/PageContainer';
import CrumbNav from '../../components/crumbNav/CrumbNav';
import Header from '../../components/header/Header';
import { ListBox } from '../../components/listBox/ListBox';
import { LABELS, QUESTIONS } from './FAQsConstants';
import faqImage from '../../assets/faq.png';
import { useStyles } from './FAQsStyles';
import FAQQuestion from '../customerLanding/faqQuestion/FAQQuestion';

interface FAQsProps {}

const FAQs: FC<FAQsProps> = () => {
  const classes = useStyles();

  return (
    <>
      <Header />
      <PageContainer>
        <ContentContainer>
          <CrumbNav steps={[{ label: 'FAQs' }]} />
          <Grid container spacing={4} style={{ justifyContent: 'space-between' }}>
            <Grid item md={5} xs={12}>
              <ListBox spacing={5}>
                <Typography color="secondary" variant="h1" className="title">
                  {LABELS.heading}
                </Typography>
                <Typography variant="h4" color="textSecondary">
                  {LABELS.subheading}
                </Typography>
              </ListBox>
            </Grid>
            <Grid item md={6} xs={12}>
              <img src={faqImage} />
            </Grid>
          </Grid>
        </ContentContainer>

        <Box className={classes.questionsContainer} mt={6}>
          <ContentContainer>
            {QUESTIONS.map((why, idx) => (
              <FAQQuestion key={idx} question={why.title} description={why.text} />
            ))}
          </ContentContainer>
        </Box>
      </PageContainer>
    </>
  );
};

export default FAQs;
