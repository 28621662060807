import { titleCaseLabels } from '../../helpers/helperFunctions';

export const LABELS = {
  ...titleCaseLabels({
    HERO_TITLE: 'Book a Local Electrician',
    GET_QUOTE: 'Get a FREE Quote',
    TITLE: 'What we can help with',
    TEXT: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
    HOW_IT_WORKS: 'How It Works',
    CHECK_PRODUCT: 'Check that this product is available in your area',
    ADD_ADDRESS2: 'Add address',
    BACK: 'Back',
    MANAGE_EVERYTHING_TITLE: "Why choose a Middy's electrician",
  }),
  HERO_TEXT:
    'Find a local, licensed electrician to help with common repairs, and maintenance around your home',
};

export const STEPS = [
  {
    title: 'Check Service Availability',
    text: 'Simply enter your postcode to check if our electrician service is available in your area. If not, make sure to sign up to our email updates!',
  },
  {
    title: 'Give us Some Additional Details',
    text: "Describe what you're needing help with and select your preferred timeframe for when you would like the work to be done.",
  },
  {
    title: 'Confirm the job',
    text: "You will then receive a notification from our installation partner 'Sorted' that details the final service cost and installation time. Confirm the job using the Sorted app or via Email.",
  },
  {
    title: 'Get the job done',
    text: "Your selected trade will arrive to do the job at the agreed time. Once the job is completed, your trade will confirm you're satisfied with the job and payment can then be finalised between you and the tradie.",
  },
];

export const WHY_CHOOSE = [
  {
    title: 'Qualified Experts',
    text: "Don't Risk It - Use a Licensed Electrician. You can be confident that the registered trade account holders are accredited, licensed and committed to meeting high quality standards.",
  },
  {
    title: 'Fast, reliable and transparent',
    text: 'Our trades will respond to your request quickly. The package price proposal includes the cost of the material and installation, with payment only required after the job has been completed!',
  },
  {
    title: 'Manage everything in the app',
    text: 'Sorted will make the process quick, easy and transparent for you. You can track your request, confirm the request, and view the completed job details with your invoice all in the one app! Rest assured trades are monitored through customer reviews and star rated to represent this so you can have peace of mind that your project will be completed with a high quality outcome.',
  },
];

export const LIST = [
  'Air conditioning or heating systems',
  'Ceiling fans',
  'Fixed appliances such as dishwashers or ovens',
  'Light fittings',
  'Switchboard and safety switches',
  'Power points',
  'Data/voice cabling',
];
