import { OfferType } from '../../models/common/OfferType';
import { MaintenanceRequest } from '../../models/maintenanceRequest/MaintenanceRequest';
import { TradeRequest } from '../../models/tradeRequest/TradeRequest';
import { TradeResponse } from '../../models/tradeResponse/TradeResponse';
import { getAddressField, getWorkFlowType } from '../confirmDetails/ConfirmDetailsUtils';
import { RegisterInterestFormValues } from '../registerInterestForm/RegisterInterestFormConstants';
import { QuoteFormValues, InterestOptionEnum } from './QuoteFormConstants';

export const workflowMapping: Record<InterestOptionEnum, OfferType> = {
  [InterestOptionEnum.GeneralElectricServices]: OfferType.ELECTRICIAN_FLOW_1,
  [InterestOptionEnum.Solar]: OfferType.SOLAR_FLOW_1,
  [InterestOptionEnum.Fans]: OfferType.BUNDLE,
  [InterestOptionEnum.ElectricSwitchboard]: OfferType.BUNDLE,
  [InterestOptionEnum.Lights]: OfferType.BUNDLE,
  [InterestOptionEnum.EVCharging]: OfferType.BUNDLE,
};

export const convertFormDataToRequest = (
  values: QuoteFormValues,
  trade?: TradeResponse,
  productDetails?: { title: string; description: string },
): Partial<MaintenanceRequest> => {
  const { userId, firstName, lastName, email, mobile, address, timeframe, interest, notes } =
    values;

  // Well, it is what it is!
  const add = address?.address?.address
    ? address?.address
    : { ...address?.address, address: getAddressField(address!.address) };

  return {
    userId: `${userId}`,
    userCreateRequest: { name: firstName, surname: lastName, phone: mobile, email },
    propertyRequest: add as any,
    details: {
      preferredAvailability: { timeSlot: 'Other', otherTime: timeframe! },
      description: `Customer has requested a callback and quote for ${interest}`,
      propertyAccess: {
        propertyAccessType: 'OWNER_OCCUPIED',
      },
      unSupervisedMinors: false,
      title: productDetails?.title || interest,
      extendedData: {},
    },
    workOrderRequest: !trade
      ? undefined
      : {
          description:
            productDetails?.description ||
            `Customer has requested a callback and quote for ${interest}`,
          category: interestToCategoryMapping(interest as InterestOptionEnum),
          propertyAccess: { propertyAccessType: 'OWNER_OCCUPIED' },
          officeIds: [trade.id],
          comments: notes,
        },
    privateWorksDetails: {
      isPrivate: true,
      privateName: 'MIDDYS',
      workflowType: getWorkFlowType(
        workflowMapping[interest as InterestOptionEnum] || OfferType.BUNDLE,
      ),
    },
    specificMaterials: [],
    lineAmountType: 'exclusive',
    location: 'GENERAL',
  };
};

export const convertToRegisterInterestPayload = (
  values: QuoteFormValues,
): RegisterInterestFormValues => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    contact: values.mobile,
    state: values.address!.address.state,
    postcode: values.address!.address.postcode,
    rooms: [],
    upgrades: [values.interest],
    timeframe: values.timeframe!,
    otherRoomPreference: values.notes,
  };
};

export const getQueryKeyForTrades = (values: QuoteFormValues): TradeRequest => {
  const { address, interest } = values;
  const {
    address: { postcode },
  } = address!;

  return {
    postcode,
    categories: [interestToCategoryMapping(interest as InterestOptionEnum)],
    privateName: 'MIDDYS',
    propertyAddress: getAddressField(address!.address),
  };
};

export enum SkillCategories {
  ELECTRICIAN_MIDDYS = 'ELECTRICIAN_MIDDYS',
  ELECTRICIAN_MIDDYS_HOME_AUTOMATION = 'ELECTRICIAN_MIDDYS_HOME_AUTOMATION',
  MIDDYS_EV_HOME_CHARGER = 'MIDDYS_EV_HOME_CHARGER',
}

export const interestToCategoryMapping = (interest: InterestOptionEnum) => {
  switch (interest) {
    case InterestOptionEnum.GeneralElectricServices:
      return SkillCategories.ELECTRICIAN_MIDDYS;

    case InterestOptionEnum.EVCharging:
      return SkillCategories.MIDDYS_EV_HOME_CHARGER;

    case InterestOptionEnum.Solar:
      return '';

    default:
      return SkillCategories.ELECTRICIAN_MIDDYS_HOME_AUTOMATION;
  }
};
