export const LABELS = {
  PACKAGES: 'Fully installed packages and services we offer',
  PACKAGES_DESCRIPTION:
    "Middy's Home Services provides end-to-end services to manage your household seamlessly, connecting you to the latest electrical products and services available locally, allowing you to optimise, protect and maintain your home or investment property.",
  SERVICES: 'Services',
  FILTER_PACKAGES: 'Filter packages by category',
  CLEAR_SELECTION: 'Clear selection',
};

export const FILTERS_ORDER = [
  'Fans',
  'Lights',
  'EV Charging',
  'Switchboard Upgrades',
  'Electrical Packages by Room',
];

export const getPackagesPageHeadings = (pkg: string): { heading: string; subheading: string } => {
  switch (pkg) {
    case 'Fans':
      return {
        heading: 'Fully Installed Ceiling Fans',
        subheading:
          'Beat the heat and cool your home in style with our extensive range of fully installed ceiling fans.',
      };

    case 'Lights':
      return {
        heading: 'Fully Installed Lights',
        subheading:
          'Explore classic and contemporary fully installed light fittings to find the perfect fit for your space.',
      };

    case 'EV Charging':
      return {
        heading: 'EV Charging Solutions',
        subheading:
          "Charge into the future with our fully installed 'plug and play' electric vehicle charger for homes.",
      };

    case 'Switchboard Upgrades':
      return {
        heading: 'Switchboard Upgrades',
        subheading:
          'Enjoy peace of mind with an electrical switchboard upgrade package, designed to help you protect what matters to you most.',
      };

    case 'Electrical Packages by Room':
      return {
        heading: 'Fully Installed Electrical Packages by Room',
        subheading:
          'Simply search by the needs of your home vision and discover the possibilities of what Electric Home Design can bring to your renovation or new build.',
      };

    default:
      return {
        heading: LABELS.PACKAGES,
        subheading: '',
      };
  }
};
