import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './CustomerLandingStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Header from '../../components/header/Header';
import BackgroundCover from '../../components/backgroundCover/BackgroundCover';
import { routes } from '../../Routes';
import {
  HIGHLIGHTED_PACKAGES,
  LABELS,
  ROOMS,
  STEPS,
  STEPS_SMALL,
} from './CustomerLandingConstants';
import { BookingActions } from '../../store/actions/BookingActions';
import { BundlesActionTypes } from '../../store/actions/BundlesActions';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import BundleCardLoading from '../../components/bundleCard/BundleCardLoading';
import HowItWorksSection from '../../components/howItWorksSection/HowItWorksSection';
import homeBanner from '../../assets/hero/hero.png';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import ActionButton from '../../components/actionButton/ActionButton';
import { ListBox } from '../../components/listBox/ListBox';
import Button from '../../components/button/Button';
import FormattedText from '../../components/formattedText/FormattedText';
import FullWidthBlueBanner from '../../components/fullWidthBlueBanner/FullWidthBlueBanner';
import ContentContainer from '../../components/containers/ContentContainer';
import SectionContent from '../../components/sectionContent/SectionContent';
import whyChooseImage from '../../assets/middysAssets/electrician2.png';
import { WHY_CHOOSE } from '../bundleDetails/BundleDetailsConstants';
import BundlesService from '../../services/bundles/BundlesService';
import { BACKGROUND_COVER_GENERIC_TEXT } from '../../Constants';
import { PackageCategory } from '../../components/header/packagesMenu/PackagesMenuConstants';
import { tradesUIURL } from '../../AppSettings';

interface CustomerLandingProps extends RouteComponentProps {
  loadingBundles: boolean;
  accessToken: string | boolean | null;

  updatePostcode: (data: string) => void;
}

const CustomerLanding: FC<CustomerLandingProps> = ({
  history,

  loadingBundles,
  accessToken,

  updatePostcode,
}) => {
  const styles = useStyles();

  useEffect(() => {
    updatePostcode('');
  }, [accessToken]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isXl = useMediaQuery('(min-width: 1921px');
  const { bundles } = BundlesService.useGetBundles(['packages']);

  return (
    <div>
      <Header />
      <BackgroundCover
        text={BACKGROUND_COVER_GENERIC_TEXT}
        landing
        loading={loadingBundles}
        backgroundImage={homeBanner}
        imgPosition={`left ${isXl ? '-120px' : '-100px'}`}
        invertImage
        showQuoteForm
        bgSize="100% 80%"
      ></BackgroundCover>
      <div className={styles.content}>
        <ContentContainer>
          <Box my={4}>
            <HowItWorksSection
              altStyle
              steps={isSmall ? STEPS_SMALL : STEPS}
              title={LABELS.HOW_IT_WORKS}
            />

            <div className={styles.section}>
              <Box
                mb={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
                gridRowGap="1em"
              >
                <Typography variant="h2" className="title" color="secondary">
                  {LABELS.ABOUT_TITLE}
                </Typography>
                <ActionButton
                  variant="outlined"
                  onClick={() => {
                    history.push(routes.packages);
                  }}
                >
                  {LABELS.VIEW_ALL}
                </ActionButton>
              </Box>
              <div className={styles.description}>
                <FormattedText>{LABELS.ABOUT_DESCRIPTION}</FormattedText>
              </div>
              <div className={styles.offersGrid}>
                {!bundles || loadingBundles ? (
                  <>
                    {[...Array(6).fill(null)].map((_, idx) => (
                      <BundleCardLoading key={idx} />
                    ))}
                  </>
                ) : (
                  <>
                    {HIGHLIGHTED_PACKAGES.map((p, idx) => (
                      <div
                        className={styles.categoryContainer}
                        key={idx}
                        onClick={() => {
                          if (p.name === 'General Electrical Services') {
                            history.push(routes.electricianQuote);
                          } else if (p.name === 'Solar') {
                            history.push(routes.solarQuote);
                          } else {
                            history.push({
                              pathname: routes.packages,
                              search: `?filter=${p.name}`,
                            });
                          }
                        }}
                      >
                        <div className={styles.imageContainer}>
                          <img
                            style={{
                              objectPosition: 'top',
                            }}
                            src={p.image}
                            className={styles.image}
                            alt="bundle"
                          />
                        </div>
                        <div className={styles.categoryContentContainer}>
                          <div className={styles.categoryTitle}>{p.name}</div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className={styles.servingAdelaideContainer}>
              <ListBox spacing={8}>
                <Typography className="title" variant="h3" color="primary">
                  {LABELS.ADELAIDE_HEADING}
                </Typography>
                <div className={styles.metroTextContainer}>
                  <Typography>{LABELS.METRO_SECTION_DESCRIPTION}</Typography>
                </div>
                <Box display="flex" justifyContent="center">
                  <Button
                    handlePress={() => {
                      history.push(routes.faqs);
                    }}
                  >
                    FAQs
                  </Button>
                </Box>
              </ListBox>
            </div>
          </Box>
        </ContentContainer>
        <FullWidthBlueBanner
          heading={LABELS.BANNER_HEADING}
          subheading={LABELS.BANNER_SUBHEADING}
          buttonLabel={LABELS.BANNER_BUTTON_LABEL}
          buttonAction={() => {
            window.open(tradesUIURL);
          }}
        />
        <ContentContainer>
          <ListBox mt={4} spacing={10}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h2" className="title" color="secondary">
                {LABELS.SOLUTIONS_HEADING}
              </Typography>
              <ActionButton
                variant="outlined"
                onClick={() => {
                  history.push({
                    pathname: routes.packages,
                    search: `?filter=${PackageCategory.ElectricalPackagesByRoom}`,
                  });
                }}
              >
                {LABELS.VIEW_ALL}
              </ActionButton>
            </Box>
            <div className={styles.solutionsContainer}>
              {ROOMS.map((room, idx) => (
                <div
                  className={styles.categoryContainer}
                  key={idx}
                  onClick={() =>
                    history.push({
                      pathname: routes.bundleDetails,
                      search: `?bundleId=${room.bundleId}`,
                    })
                  }
                >
                  <div className={styles.imageContainer}>
                    <img src={room.image} className={styles.image} alt="bundle" />
                  </div>
                  <div className={styles.categoryContentContainer}>
                    <div className={styles.categoryTitle}>{room.name}</div>
                  </div>
                </div>
              ))}
            </div>

            <SectionContent
              title={LABELS.WHY_CHOOSE}
              image={whyChooseImage}
              reversed
              headingPrimary
              imgFill
              disableTopMargin
            >
              <ListBox mt={4} spacing={6}>
                {WHY_CHOOSE.map((why) => (
                  <ListBox spacing={2}>
                    <Typography variant="h3">{why.title}</Typography>
                    <Typography>{why.text}</Typography>
                  </ListBox>
                ))}
              </ListBox>
            </SectionContent>
          </ListBox>
        </ContentContainer>
      </div>
    </div>
  );
};

const loading = loadingSelector([BundlesActionTypes.GET_BUNDLES]);

const mapStateToProps = (state: ApplicationState) => ({
  accessToken: state.token.accessToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updatePostcode: (data: string) => dispatch(BookingActions.updatePostcode(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerLanding));
