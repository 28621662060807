export const LABELS = {
  heading: 'Frequently Asked Questions',
  subheading:
    "Need help with the Middy's Home Services website? Browse our most frequently asked questions.",
};

export const QUESTIONS = [
  {
    title: "Are Middy's quotes free?",
    text: "Yes, Middy's Home Services provides free quotes for all our packages and services. When you request a quote, a licensed electrician will assess your needs and provide a detailed estimate without any obligation to proceed. Our goal is to ensure you have all the necessary information to make an informed decision about your home improvement projects.",
  },
  {
    title: "How does Middy's assess and select tradies?",
    text: "Middy's ensures compliance through initial screening, licensing verification, onboarding, ongoing monitoring, and periodic re-evaluation.",
  },
  {
    title: 'Is a package available in my area?',
    text: "Middy's Home Services are currently available across most Adelaide metro postcodes as well as postocdes within central Mildura. Search your postcode at the top of the page to find out what packages are near you. You can also <a to={routes.registerInterest}>register your intertest</a> and receive alerts when Home Services come to your area.",
  },
  // {
  //   title: "Does Middy's help with 24/7 emergency repairs?",
  //   text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  // },
  {
    title: 'How do I cancel or update my job booking?',
    text: 'To cancel or update your job booking simply head to the Sorted App and speak directly to your selected Electrician. <a to={https://apps.apple.com/au/app/sorted/id1232960956}>Download Sorted on the App Store</a> or <a to={https://play.google.com/store/apps/details?id=com.sortedservices.sorted&hl=en}>Get it On Google Play</a>',
  },
  {
    title: 'I have feedback/complaint, where can I go?',
    text: `We value your feedback and are committed to providing the best service possible. If you have feedback or a complaint, you can reach out to us at hello@middys.com.au`,
  },
  {
    title: "Does Middy's offer warranty on material and labour?",
    text: "Middy's offer warranties on products used by the nominated trade specific to Middy's Home Service Packages.",
  },
  {
    title: "I am a Middy's contractor, how I can sign-up to MTA?",
    text: "If you’re a Middy's trade customer looking to find out more and sign up for the Middy’s Trade Accelerator (MTA), Visit the <a to={https://www.middys.com.au/services/business-support/trade-accelerator}>MTA Sign-Up and information Page</a>",
  },
];
