import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { LABELS, MENU_COLUMNS, PackageCategory, getIcon } from './PackagesMenuConstants';
import { CancelOutlined } from '@material-ui/icons';
import { BundlePackage } from '../../../models/bundles/Bundles';
import { useStyles } from './PackagesMenuStyles';
import { groupBy } from 'lodash';
import { theme } from '../../../theme/Theme';
import MiddysLink from '../../link/MiddysLink';
import { routes } from '../../../Routes';
import { useHistory } from 'react-router';
import ActionButton from '../../actionButton/ActionButton';
import { ListBox } from '../../listBox/ListBox';

interface PackagesMenuProps {
  packages: BundlePackage[] | undefined;
  loading: boolean;
  onClose: () => void;
}

export const PackagesMenu: FC<PackagesMenuProps> = ({ packages, loading, onClose }) => {
  const classes = useStyles();
  const history = useHistory();

  const data = packages && groupBy(packages, (p) => p.category!.title);

  const renderPackages = (packageCategory: PackageCategory) => {
    return (
      <Box className={classes.packageDetailsContainer}>
        <MiddysLink
          onClick={() => {
            onClose();
          }}
          to={{ pathname: routes.packages, search: `?filter=${packageCategory}` }}
        >
          <Box display="flex" gridGap="0.5em">
            <img src={getIcon(packageCategory)} />
            <Typography style={{ fontWeight: 500 }} variant="h5">
              {packageCategory}
            </Typography>
          </Box>
        </MiddysLink>
        {data![packageCategory].map((item) => (
          <Box
            onClick={() => {
              history.push(`${routes.bundleDetails}?bundleId=${item.productId}`);
              onClose();
            }}
          >
            <Typography style={{ fontSize: theme.font.s }}>
              {item.packageName || item.standard.title}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box className={classes.container} px={3} py={1}>
      {loading || !packages ? (
        <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center">
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography className="title" color="secondary" variant="h3">
              {LABELS.products}
            </Typography>
            <Typography
              onClick={() => {
                onClose();
              }}
              className={classes.closeMenuButton}
              style={{ fontSize: theme.font.s }}
            >
              {LABELS.close} <CancelOutlined />
            </Typography>
          </Box>

          <Box display="flex" gridGap="2em" alignItems="flex-start" flexWrap="wrap">
            <Box my={3} className={classes.packagesListContainer}>
              {/* Col 1 */}
              <ListBox className={classes.packageColumn} spacing={4}>
                <ListBox spacing={8}>
                  {MENU_COLUMNS.COL_1.map((p) => (
                    <Box>{renderPackages(p)}</Box>
                  ))}
                </ListBox>
              </ListBox>
              {/* Col 2 */}
              <ListBox className={classes.packageColumn} spacing={4}>
                <ListBox spacing={8}>
                  {MENU_COLUMNS.COL_2.map((p) => (
                    <Box>{renderPackages(p)}</Box>
                  ))}
                </ListBox>
              </ListBox>
              {/* Col 3 */}
              <ListBox className={classes.packageColumn} spacing={4}>
                <Box display="flex" gridGap="0.5em">
                  <img src={getIcon(PackageCategory.Services)} />
                  <Typography style={{ fontWeight: 500 }} variant="h5">
                    {LABELS.electricalServices}
                  </Typography>
                </Box>

                <Box className={classes.packageDetailsContainer}>
                  <MiddysLink to={routes.electricianQuote}>
                    <Typography style={{ fontSize: theme.font.s }}>{LABELS.electrician}</Typography>
                  </MiddysLink>
                  <MiddysLink to={routes.solarQuote}>
                    <Typography style={{ fontSize: theme.font.s }}>{LABELS.solar}</Typography>
                  </MiddysLink>

                  <MiddysLink to={routes.packages}>
                    <ActionButton variant="outlined">{LABELS.viewAll}</ActionButton>
                  </MiddysLink>
                </Box>
              </ListBox>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
