import { theme } from '../../theme/Theme';
import { Theme, makeStyles } from '@material-ui/core';
import { pageContainer } from '../../theme/GlobalStyles';
import { SIZES } from '../../Constants';

export const useStyles = makeStyles<Theme, { expanded?: boolean }>({
  headerContainer: ({ expanded }) => ({
    backgroundColor: theme.colors.secondary,
    position: 'relative',
    zIndex: 1,
    height: expanded ? 220 : undefined,
  }),
  headerBar: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    // paddingLeft: theme.units.padding * 8,
  },
  logo: {
    height: 65,
    objectFit: 'contain',
    cursor: 'pointer',
  },
  background: {
    position: 'absolute',
    height: 220,
    width: '100vw',
    zIndex: 4,
    objectPosition: 'center',
    objectFit: 'cover',
  },
  darken: {
    position: 'absolute',
    height: 220,
    width: '100vw',
    backgroundColor: '#000',
    opacity: 0.5,
    zIndex: 5,
  },
  headerExpanded: {
    position: 'relative',
    height: 140,
    paddingTop: theme.units.padding * 8,
  },
  expandedWrapper: {
    ...pageContainer,
    zIndex: 6,
  },
  arrowContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.units.padding,
  },
  arrow: {
    height: theme.units.iconHeight,
    marginRight: theme.units.padding * 4,
    zIndex: 6,
    cursor: 'pointer',
  },
  arrowHeader: {
    height: theme.units.iconHeight,
    marginRight: theme.units.padding * 4,
    zIndex: 6,
    cursor: 'pointer',
    display: 'none',
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'block',
    },
  },
  arrowText: {
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    color: theme.colors.light,
    zIndex: 6,
  },
  expandedTitle: {
    fontFamily: theme.font.family,
    fontSize: theme.font.xxl,
    fontWeight: 500,
    color: theme.colors.light,
    marginTop: theme.units.padding * 3,
    marginLeft: theme.units.padding,
    zIndex: 6,
  },
  expandedSubtext: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    color: theme.colors.light,
    fontWeight: 100,
    marginTop: theme.units.padding * 2,
    marginLeft: theme.units.padding,
    zIndex: 6,
  },
  expandedBehind: {
    position: 'absolute',
    bottom: -48,
    left: 0,
    right: 0,
    height: 48,
    backgroundColor: theme.colors.secondary,
  },
  toolbar: {
    justifyContent: 'center',
    gap: '3em',
    background: 'white',
    color: 'black',
    padding: '0 1em',
  },
  toolbarInline: {
    justifyContent: 'flex-end',
    gap: '3em',
    color: 'white',
    backgroundColor: 'transparent',
    fontWeight: 500,
  },
  stick: {
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 100000,
  },
  clickableText: {
    cursor: 'pointer',
    '&:hover': { color: theme.colors.secondary },
  },
  packagesDialog: {
    top: 32,
    width: '100% !important',
    maxHeight: 'calc(100% - 64px) !important',
  },
  pointer: {
    cursor: 'pointer',
  },
});
