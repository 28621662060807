import { theme } from '../../theme/Theme';
import { Theme, makeStyles } from '@material-ui/core';
import { SIZES } from '../../Constants';
import { loadingAnimation, pageContainer } from '../../theme/GlobalStyles';

const HEIGHT = 500;
const SMALL_HEIGHT = 360;
const LANDING_HEIGHT = 800;

interface StyleProps {
  imgPosition?: string;
  landing?: boolean;
  invertImage?: boolean;
  backgroundImage?: string;
  bgSize?: string;
  showQuoteForm?: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>({
  backgroundContainer: ({ backgroundImage, bgSize, imgPosition }) => ({
    position: 'relative',

    '&::before': {
      content: '""', // Corrected content syntax
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '80%', // Restricts background to 80%
      backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
      backgroundSize: 'cover',
      backgroundPosition: imgPosition || 'left center',
      backgroundRepeat: 'no-repeat',
      zIndex: -1, // Ensures it stays behind the content
    },
  }),

  backgroundWrapper: (props) => ({
    width: '100vw',
    display: 'flex',
    zIndex: 0,
    justifyContent: 'center',
    minHeight: '80vh',
  }),
  backgroundImage2: (props) => ({
    position: 'relative',
    height: HEIGHT,
    width: '100vw',
    objectPosition: props?.imgPosition || 'center',
    objectFit: 'cover',
    ...loadingAnimation,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      height: SMALL_HEIGHT,
    },
    filter: 'brightness(0.4)',
  }),
  trapezoid: ({ showQuoteForm }) => ({
    minHeight: 220,
    height: showQuoteForm ? '60%' : '30%',
    zIndex: 2,
    width: '100%',
    background: '#ec008c',
    clipPath: 'polygon(0 0, 100% 25%, 100% 100%, 0% 100%)',
    alignSelf: 'flex-end',
    position: 'absolute',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      height: showQuoteForm ? 420 : 300,
    },
  }),

  darken: {
    position: 'absolute',
    height: HEIGHT,
    width: '100vw',
    backgroundColor: '#000',
    opacity: 0.5,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      height: SMALL_HEIGHT,
    },
  },
  contentStart: {
    height: HEIGHT,
    width: '100vw',
    ...loadingAnimation,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      height: SMALL_HEIGHT,
    },
  },
  inset: {
    height: 260,
    width: '100vw',
  },
  children: {
    position: 'absolute',
    width: '100vw',
    top: 260,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      top: 200,
    },
  },
  textContainer: {},
  textWrapper: {
    position: 'relative',
  },
  preHeader: {
    fontSize: theme.font.l,
    fontFamily: theme.font.family2,
    textTransform: 'uppercase',
    color: theme.colors.light,
    fontWeight: 600,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.m,
    },
  },
  headline: {
    fontSize: theme.font.xxl,
    fontFamily: theme.font.family2,
    color: theme.colors.light,
    fontWeight: 600,
    marginTop: theme.units.margin * 30,
    marginBottom: theme.units.margin * 2,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.xxl,
    },
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginTop: theme.units.margin * 10,
    },
  },
  introText: {
    fontSize: theme.font.m,
    fontFamily: theme.font.family2,
    color: theme.colors.light,
    maxWidth: 520,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.m,
    },
  },
  text: {
    fontSize: theme.font.xxxl,
    fontFamily: theme.font.family2,
    color: theme.colors.light,
    fontWeight: 600,
    [`@media (max-width: ${SIZES.md}px)`]: {
      fontSize: theme.font.xxl,
    },
  },
  // Loading
  loadingPreHeader: {
    width: 140,
    height: 24,
    backgroundColor: theme.colors.light,
  },
  loadingHeadline: {
    width: 260,
    height: 40,
    backgroundColor: theme.colors.light,
    marginTop: theme.units.margin * 2,
    marginBottom: theme.units.margin * 2,
  },
  loadingIntroText: {
    width: 140,
    height: 24,
    backgroundColor: theme.colors.light,
  },
  contentContainer: {
    zIndex: 5,
    width: '100%',
    height: '100%',
    padding: '2em 0',
    maxWidth: '80%',
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'flex-end',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flexWrap: 'wrap-reverse',
    },
    [`@media (max-width: ${SIZES.sm}px)`]: {
      maxWidth: '90%',
    },
    alignItems: 'center',
  },
  formWrapper: {
    flexGrow: 1,
    [`@media (min-width: ${SIZES.lg}px)`]: {
      marginLeft: theme.units.margin * 10,
    },
    [`@media (min-width: ${SIZES.md}px) and (max-width: ${SIZES.lg})px`]: {
      marginBottom: '2em',
    },
    display: 'flex',
    justifyContent: 'flex-end',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      justifyContent: 'flex-start',
    },
  },
  collapsedFormContainer: {
    marginTop: '2em',
    padding: '1em',
    background: 'white',
    cursor: 'pointer',
  },
  arrowContainer: {
    background: theme.colors.secondary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    width: 50,
    borderRadius: '50%',
  },
});
