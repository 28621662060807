import React, { FC, useState } from 'react';
import { useStyles } from './BackgroundCoverStyles';
import { theme as middysTheme } from '../../theme/Theme';
import BackgroundCoverLoading from './BackgroundCoverLoading';
import QuoteForm from '../../containers/quoteForm/QuoteForm';
import {
  AppBar,
  Box,
  Dialog,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { ListBox } from '../listBox/ListBox';
import { LABELS } from './BackgroundCoverConstants';
import { ArrowDownward, Close } from '@material-ui/icons';
import ContentContainer from '../containers/ContentContainer';
import FormattedText from '../formattedText/FormattedText';
import CollapsedFormButton from '../../containers/quoteForm/CollapsedFormButton';
import FullScreenQuoteFormDialog from '../../containers/quoteForm/FullScreenQuoteFormDialog';

interface BackgroundCoverProps {
  darken?: boolean;
  landing?: boolean;
  inset?: boolean;
  title?: string;
  text?: string;
  children?: React.ReactNode;
  backgroundImage?: string;
  loading?: boolean;
  imgPosition?: string;
  form?: boolean;
  content?: React.ReactNode;
  invertImage?: boolean;
  showQuoteForm?: boolean;
  bgSize?: string;
}

const BackgroundCover: FC<BackgroundCoverProps> = ({
  inset,
  title,
  text,
  landing,
  children,
  backgroundImage,
  loading,
  imgPosition,
  invertImage,
  showQuoteForm,
  bgSize,
}) => {
  const [collapsedQuoteForm, setCollapsedQuoteForm] = useState(true);

  const theme = useTheme();
  const styles = useStyles({
    imgPosition,
    landing,
    invertImage,
    backgroundImage,
    bgSize,
    showQuoteForm,
  });
  const isSmall = useMediaQuery<Theme>(theme.breakpoints.down('xs'));

  return (
    <>
      {loading ? (
        <BackgroundCoverLoading landing={landing} inset={inset} children={children} />
      ) : (
        <div className={styles.backgroundContainer}>
          {landing ? (
            <div className={styles.backgroundWrapper}>
              <div className={styles.trapezoid}></div>

              <div className={styles.contentContainer}>
                <div className={styles.textWrapper}>
                  <div className={styles.headline}>{title || "Middy's Home Services"}</div>
                  <div className={styles.introText}>
                    <FormattedText>
                      {text || 'Whilst supporting our electrical contractors'}
                    </FormattedText>
                  </div>
                  {isSmall && collapsedQuoteForm && showQuoteForm && (
                    <CollapsedFormButton
                      onClick={() => {
                        setCollapsedQuoteForm(false);
                      }}
                    />
                  )}
                </div>
                {!isSmall && showQuoteForm && (
                  <div className={styles.formWrapper}>
                    <QuoteForm />
                  </div>
                )}
                {isSmall && !collapsedQuoteForm && (
                  <FullScreenQuoteFormDialog
                    onClose={() => {
                      setCollapsedQuoteForm(true);
                    }}
                  />
                )}
              </div>
            </div>
          ) : (
            <img
              src={backgroundImage || middysTheme.hero}
              className={styles.backgroundImage2}
              alt="hero"
            />
          )}

          {!!children && <div className={styles.children}>{children}</div>}
          {/* <div className={inset ? styles.inset : styles.contentStart} /> */}
        </div>
      )}
    </>
  );
};

export default BackgroundCover;
