import React, { FC, useEffect } from 'react';
import { useStyles } from './NewsLetterFormStyles';
import Box from '@material-ui/core/Box';
import { ListBox } from '../../../components/listBox/ListBox';
import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import {
  LABELS,
  NEWSLETTER_FORM_FIELDS,
  NEWSLETTER_FORM_LABELS,
  VALIDATION_SCHEMA,
} from './NewsLetterFormConstants';
import { Formik } from 'formik';
import MiddysTextField from '../../../components/middysFields/MiddysTextField';
import Button from '../../../components/button/Button';
import { theme as middysTheme } from '../../../theme/Theme';
import { MIDDYS_SOCIALS_LINKS, TERMS_LABEL, TERMS_LINK } from '../../../Constants';
import BookingService from '../../../services/booking/BookingService';
import { CheckCircleOutline } from '@material-ui/icons';
import instagram from '../../../assets/socials/instagram.png';
import facebook from '../../../assets/socials/facebook.png';
import linkedIn from '../../../assets/socials/linkedIn.png';
import completeIcon from '../../../assets/success-check.png';
import { useLocation } from 'react-router';

interface NewsLetterFormProps {}

const NewsLetterForm: FC<NewsLetterFormProps> = () => {
  const styles = useStyles();
  const { subscribe, subscribeLoading, subscribeSuccess, resetSubscribe } =
    BookingService.useNewsLetterSubscribe();
  const location = useLocation();

  useEffect(() => {
    // Reset success state whenever the route changes
    if (subscribeSuccess) {
      resetSubscribe();
    }
  }, [location.pathname]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box className={styles.container}>
      {!subscribeSuccess ? (
        <>
          <ListBox mb={4} spacing={3}>
            <Typography
              variant="h3"
              className="title"
              style={{ color: middysTheme.colors.secondary }}
            >
              {LABELS.heading}
            </Typography>
            <Typography style={{ fontSize: 14 }} color="textSecondary">
              {LABELS.subheading}
            </Typography>
          </ListBox>
          <Formik
            initialValues={{
              name: '',
              email: '',
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={(values) => {
              subscribe({ ...values, source: 'MIDDYS' });
            }}
          >
            {({ handleSubmit }) => (
              <>
                <ListBox spacing={4}>
                  <ListBox flexWrap={isSmall ? 'wrap' : 'nowrap'} flexDirection="row" spacing={8}>
                    <MiddysTextField
                      required
                      label={NEWSLETTER_FORM_LABELS.name}
                      placeholder={NEWSLETTER_FORM_LABELS.name}
                      name={NEWSLETTER_FORM_FIELDS.name}
                      altStyle
                    />

                    <MiddysTextField
                      required
                      label={NEWSLETTER_FORM_LABELS.email}
                      placeholder={NEWSLETTER_FORM_LABELS.email}
                      name={NEWSLETTER_FORM_FIELDS.email}
                      altStyle
                    />
                  </ListBox>
                </ListBox>
                <Box mt={2} display="flex" justifyContent="flex-end">
                  <ListBox alignItems="flex-end" mt={2} spacing={4}>
                    <Box maxWidth="200px">
                      <Button disabled={subscribeLoading} handlePress={handleSubmit}>
                        {subscribeLoading ? LABELS.subscribing : LABELS.buttonLabel}
                      </Button>
                    </Box>
                    <Typography color="textSecondary" variant="h6">
                      {TERMS_LABEL}
                      <a
                        style={{ color: middysTheme.colors.secondary }}
                        target="_blank"
                        href={TERMS_LINK}
                        rel="noopener noreferrer"
                      >
                        <span color={middysTheme.colors.textSecondary}>
                          <u>Middy's Privacy Policy</u>
                        </span>
                      </a>
                    </Typography>
                  </ListBox>
                </Box>
              </>
            )}
          </Formik>
        </>
      ) : (
        <ListBox className={styles.successMessageContainer} spacing={4}>
          <img src={completeIcon} className={styles.iconStyle} alt="complete" />
          <Typography className="title" color="secondary" variant="h3">
            {LABELS.successHeading}
          </Typography>
          <Typography>{LABELS.successMessageLine1}</Typography>
          <Typography>{LABELS.successMessageLine2}</Typography>
          <Typography>{LABELS.successMessageLine3}</Typography>

          <div className={styles.socialsContainer}>
            <div className={styles.socialIcons}>
              <img
                onClick={() => window.open(MIDDYS_SOCIALS_LINKS.FACEBOOK_LINK, '_blank')}
                src={facebook}
                className={styles.socialIcon}
                alt="facebook"
              />
              <img
                onClick={() => window.open(MIDDYS_SOCIALS_LINKS.INSTAGRAM_LINK, '_blank')}
                src={instagram}
                className={styles.socialIcon}
                alt="instagram"
              />
              <img
                onClick={() => window.open(MIDDYS_SOCIALS_LINKS.LINKEDIN, '_blank')}
                src={linkedIn}
                className={styles.socialIcon}
                alt="linkedin"
              />
            </div>
          </div>
        </ListBox>
      )}
    </Box>
  );
};

export default NewsLetterForm;
