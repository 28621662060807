import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './ElectricianQuoteStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Header from '../../components/header/Header';
import BackgroundCover from '../../components/backgroundCover/BackgroundCover';
import ElectricianBG from '../../assets/electrician/hero.jpg';
import Clip from '../../assets/electrical-quote.png';
import ContactUs from '../../assets/contact-us-cropped.png';
import { LABELS, LIST, WHY_CHOOSE } from './ElectricianQuoteConstants';
import { routes } from '../../Routes';
import { BookingActions } from '../../store/actions/BookingActions';
import { OfferType } from '../../models/common/OfferType';
import SectionContent from '../../components/sectionContent/SectionContent';
import HowItWorksSection from '../../components/howItWorksSection/HowItWorksSection';
import { UpdateOfferType } from '../../models/common/UpdateOfferTypeRequest';
import { getSteps } from '../../helpers/StepHelper';
import { mockDataServices } from '../../mock/BundleMock';
import AddressCheckFooter from '../../components/addressCheckFooter/AddressCheckFooter';
import { STEPS } from '../customerLanding/CustomerLandingConstants';
import { AppBar, Box, Dialog, Toolbar, useMediaQuery } from '@material-ui/core';
import QuoteForm from '../quoteForm/QuoteForm';
import { theme } from '../../theme/Theme';
import ContentContainer from '../../components/containers/ContentContainer';
import { Close } from '@material-ui/icons';
import FullScreenQuoteFormDialog from '../quoteForm/FullScreenQuoteFormDialog';
import CollapsedFormButton from '../quoteForm/CollapsedFormButton';

interface ElectricianQuoteProps extends RouteComponentProps {
  updateOfferType: (data: UpdateOfferType) => void;
  updateSkills: (data: string[]) => void;
}

const ElectricianQuote: FC<ElectricianQuoteProps> = ({
  history,
  updateOfferType,
  location,
  updateSkills,
}) => {
  const styles = useStyles();
  const [collapsedQuoteForm, setCollapsedQuoteForm] = useState<boolean>(true);

  const setSkills = () => {
    const pathName = location.pathname;
    const currentService = mockDataServices.find((data) => data.location === pathName);
    const skills = currentService ? currentService.skills : [];
    updateSkills(skills);
  };
  const onSubmit = () => {
    updateOfferType({
      offerType: OfferType.ELECTRICIAN_FLOW_1,
      steps: getSteps(OfferType.ELECTRICIAN_FLOW_1),
    });
    setSkills();
    history.push(routes.jobDetails);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const hideQuoteForm = useMediaQuery('(max-width: 1096px)');

  return (
    <div>
      <Header />
      <BackgroundCover darken backgroundImage={ElectricianBG}>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <div className={styles.headerTitle}>{LABELS.HERO_TITLE}</div>
            <div className={styles.headerSubtitle}>{LABELS.HERO_TEXT}</div>
          </div>
        </div>
      </BackgroundCover>
      <div className={styles.container}>
        <Box justifyContent="space-between" my={8} display="flex">
          <Box flexBasis={hideQuoteForm ? '100%' : '50%'}>
            <SectionContent
              title={LABELS.TITLE}
              list={LIST}
              image={hideQuoteForm ? undefined : Clip}
              disableTopMargin
              containerClass={styles.sectionContainer}
              imgClass={styles.sectionImg}
            >
              {hideQuoteForm && (
                <CollapsedFormButton
                  onClick={() => {
                    setCollapsedQuoteForm(false);
                  }}
                />
              )}
            </SectionContent>
          </Box>
          {!hideQuoteForm && (
            <Box flexBasis="50%">
              <QuoteForm />
            </Box>
          )}
        </Box>
        <HowItWorksSection altStyle steps={STEPS} title={LABELS.HOW_IT_WORKS} />
        <SectionContent
          headingPrimary
          title={LABELS.MANAGE_EVERYTHING_TITLE}
          image={ContactUs}
          reversed
        >
          {WHY_CHOOSE.map((why) => (
            <>
              <div className={styles.stepTitle}>{why.title}</div>
              <div className={styles.stepText}>{why.text}</div>
            </>
          ))}
        </SectionContent>
      </div>
      {/* <Footer
        secondaryText={LABELS.BACK}
        secondaryAction={() => history.goBack()}
        primaryText={LABELS.GET_QUOTE}
        primaryAction={() => onSubmit()}
      /> */}
      {hideQuoteForm && !collapsedQuoteForm && (
        <FullScreenQuoteFormDialog
          onClose={() => {
            setCollapsedQuoteForm(true);
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateOfferType: (data: UpdateOfferType) => dispatch(BookingActions.updateOfferType(data)),
  updateSkills: (data: string[]) => dispatch(BookingActions.updateSkills(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ElectricianQuote));
