import fans from '../../assets/customerLandingImages/fans.jpg';
import lights from '../../assets/customerLandingImages/lights.png';
import charging from '../../assets/customerLandingImages/charging.png';
import switchboard from '../../assets/customerLandingImages/switchboard.png';
import electrical from '../../assets/customerLandingImages/electrical.png';
import solar from '../../assets/customerLandingImages/solar.png';
import kitchen from '../../assets/customerLandingImages/kitchen.png';
import bathroom from '../../assets/customerLandingImages/bathroom.png';
import outside from '../../assets/customerLandingImages/outside.png';
import smartHome from '../../assets/customerLandingImages/smart.png';

export const LABELS = {
  PAGE_TITLE: "Middy's Home Services",
  PAGE_SUBTITLE:
    'Connect with qualified, licenced electricians in your local area for the installation of electrical products, packages and services',
  FULLY_INSTALLED: 'Fully Installed Packages',
  SEE_ALL: 'See all packages',
  START_HERE: 'Start here - For Trades and Services',
  ABOUT_TITLE: 'Fully installed electrical products',
  ABOUT_DESCRIPTION: `With a wide range of fully installed electrical products and services available locally, Middy's Home services allows you to optimise, protect and maintain your home or investment property.`,
  HOW_IT_WORKS: 'How it works',
  FAQS: 'Frequently asked questions',
  SERVICING_METRO_ONLY: 'Currently Servicing Adelaide Metro Only ',
  METRO_DESCRIPTION:
    "With a wide range of home automation solutions, Middy's Home Services provides end-to-end services to manage your household seamlessly, connecting you to the latest electrical products and services available locally to allow you to optimise, protect and maintain your home or investment.",
  GET_NOTIFIED: 'Get notified',
  VIEW_ALL: 'View all',
  ADELAIDE_HEADING: "Middy's Home Services is Currently Servicing Adelaide Metro Only",
  METRO_SECTION_DESCRIPTION: `Simply choose from our wide range of fully installed electrical products, or search for a solution by room.\nSubmit an online enquiry and you will be contacted by our nearest qualified licensed qualified electrician who will \n provide a FREE quote. Once you accept the quote, our electrician will arrive at the scheduled time to \ncomplete the job, then you can then finalise the payment once satisfied.`,
  BANNER_HEADING: 'Electrical Contractor or Installer?',
  BANNER_SUBHEADING:
    "Learn how Middy's Home Services can connect you to more local customers and save you time.",
  BANNER_BUTTON_LABEL: 'Register Now',
  SOLUTIONS_HEADING: 'Electrical Solutions By Room',
  WHY_CHOOSE: 'Why Choose a Fully Installed Electrical Product',
};

export const STEPS = [
  {
    title: 'Select an Electrical \n Product or Service',
  },
  {
    title: 'Connect With A \n Local Electrician',
  },
  {
    title: 'Get The \n Job Done!',
  },
];

export const STEPS_SMALL = [
  {
    title: 'Select an Electrical Product or Service',
  },
  {
    title: 'Connect With A Local Electrician',
  },
  {
    title: 'Get The Job Done!',
  },
];

export const HIGHLIGHTED_PACKAGES = [
  { name: 'Fans', image: fans },
  { name: 'Lights', image: lights },
  { name: 'EV Charging', image: charging },
  { name: 'Switchboard Upgrades', image: switchboard },
  { name: 'General Electrical Services', image: electrical },
  { name: 'Solar', image: solar },
];

export const ROOMS = [
  { name: 'Kitchen', image: kitchen, bundleId: 1 },
  { name: 'Bathroom', image: bathroom, bundleId: 2 },
  { name: 'Outdoor', image: outside, bundleId: 3 },
  { name: 'Smart Home', image: smartHome, bundleId: 4 },
];

export const BUNDLES_PRIORITY = ['Fans', 'Lights', 'Home Automation', 'EV Charging'];
