import smartHome from '../../../assets/smart-home.svg';
import electrical from '../../../assets/electrical.svg';
import lights from '../../../assets/lights.svg';
import fans from '../../../assets/fans.svg';
import tools from '../../../assets/tools.svg';
import lightSwitch from '../../../assets/light-switch.svg';

export const LABELS = {
  close: 'Close menu',
  packages: 'Fully Installed Products',
  services: 'Services',
  electrician: 'Book an Electrician',
  solar: 'Solar Proposal',
  viewAll: 'View all',
  electricalSolByRoom: 'Fully Installed Electrical Solutions by Room ',
  electricalServices: 'Electrical Services',
  products: 'Products',
};

export enum PackageCategory {
  Lights = 'Lights',
  EVCharging = 'EV Charging',
  SwitchboardUpgrades = 'Switchboard Upgrades',
  Fans = 'Fans',
  Services = 'Services',
  ElectricalPackagesByRoom = 'Electrical Packages by Room',
}
export const getIcon = (type: PackageCategory) => {
  switch (type) {
    case PackageCategory.ElectricalPackagesByRoom:
      return smartHome;

    case PackageCategory.EVCharging:
      return electrical;

    case PackageCategory.Lights:
      return lights;

    case PackageCategory.Fans:
      return fans;

    case PackageCategory.Services:
      return tools;

    case PackageCategory.SwitchboardUpgrades:
      return lightSwitch;

    default:
      return '';
  }
};

export const MENU_COLUMNS = {
  COL_1: [PackageCategory.Fans],
  COL_2: [
    PackageCategory.Lights,
    PackageCategory.EVCharging,
    PackageCategory.SwitchboardUpgrades,
    PackageCategory.ElectricalPackagesByRoom,
  ],
};
