import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './SolarQuoteStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Header from '../../components/header/Header';
import BackgroundCover from '../../components/backgroundCover/BackgroundCover';
import SolarBG from '../../assets/solar/hero.jpg';
import SolarInstallation from '../../assets/solar/solar.png';
import { LABELS, LIST, STEPS } from './SolarQuoteConstants';
import Footer from '../../components/footer/Footer';
import { routes } from '../../Routes';
import ContactUs from '../../assets/contact-us-cropped.png';
import { OfferType } from '../../models/common/OfferType';
import { BookingActions } from '../../store/actions/BookingActions';
import SectionContent from '../../components/sectionContent/SectionContent';
import HowItWorksSection from '../../components/howItWorksSection/HowItWorksSection';
import { UpdateOfferType } from '../../models/common/UpdateOfferTypeRequest';
import { getSteps } from '../../helpers/StepHelper';
import ContentContainer from '../../components/containers/ContentContainer';
import { Box, useMediaQuery } from '@material-ui/core';
import QuoteForm from '../quoteForm/QuoteForm';
import CollapsedFormButton from '../quoteForm/CollapsedFormButton';
import FullScreenQuoteFormDialog from '../quoteForm/FullScreenQuoteFormDialog';
import { InterestOptionEnum } from '../quoteForm/QuoteFormConstants';

interface SolarQuoteProps extends RouteComponentProps {
  updateOfferType: (data: UpdateOfferType) => void;
}

const SolarQuote: FC<SolarQuoteProps> = ({ history, updateOfferType }) => {
  const styles = useStyles();
  const [collapsedQuoteForm, setCollapsedQuoteForm] = useState<boolean>(true);

  const onSubmit = () => {
    updateOfferType({ offerType: OfferType.SOLAR_FLOW_1, steps: getSteps(OfferType.SOLAR_FLOW_1) });
    history.push(routes.jobDetails);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const hideQuoteForm = useMediaQuery('(max-width: 1096px)');

  return (
    <div>
      <Header />
      <BackgroundCover darken backgroundImage={SolarBG}>
        <ContentContainer>
          <div className={styles.headerContainer}>
            <div className={styles.headerTitle}>{LABELS.HERO_TITLE}</div>
            <div className={styles.headerSubtitle}>{LABELS.HERO_TEXT}</div>
          </div>
        </ContentContainer>
      </BackgroundCover>
      <div className={styles.container}>
        <Box justifyContent="space-between" my={8} display="flex">
          <Box flexBasis={hideQuoteForm ? '100%' : '50%'} alignSelf="flex-start">
            <SectionContent
              title={LABELS.TITLE}
              text={LABELS.TEXT}
              image={hideQuoteForm ? undefined : SolarInstallation}
              disableTopMargin
              containerClass={styles.sectionContainer}
              imgClass={styles.sectionImg}
            >
              {hideQuoteForm && (
                <CollapsedFormButton
                  onClick={() => {
                    setCollapsedQuoteForm(false);
                  }}
                />
              )}
            </SectionContent>
          </Box>
          {!hideQuoteForm && (
            <Box flexBasis="50%">
              <QuoteForm fixedInterest={InterestOptionEnum.Solar} />
            </Box>
          )}
        </Box>

        <HowItWorksSection altStyle steps={STEPS} title={LABELS.HOW_IT_WORKS} />
        <SectionContent
          title={LABELS.MANAGE_EVERYTHING_TITLE}
          image={ContactUs}
          reversed
          list={LIST}
        />
        {hideQuoteForm && !collapsedQuoteForm && (
          <FullScreenQuoteFormDialog
            onClose={() => {
              setCollapsedQuoteForm(true);
            }}
            quoteFormProps={{ fixedInterest: InterestOptionEnum.Solar }}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateOfferType: (data: UpdateOfferType) => dispatch(BookingActions.updateOfferType(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SolarQuote));
