import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import MiddysLink from '../link/MiddysLink';
import { routes } from '../../Routes';
import { theme } from '../../theme/Theme';

export interface FormattedTextProps {
  newLineSpace?: number;
  children: string;
}

function FormattedText(props: FormattedTextProps) {
  const { children, newLineSpace } = props;

  const parseText = (text: string) => {
    const parts = [];
    let lastIndex = 0;

    while (true) {
      const startIndex = text.indexOf('<a to={', lastIndex);
      if (startIndex === -1) {
        // No more links, add remaining text
        parts.push(text.slice(lastIndex));
        break;
      }

      const endIndex = text.indexOf('</a>', startIndex);
      if (endIndex === -1) {
        // Malformed link, treat the rest as plain text
        parts.push(text.slice(lastIndex));
        break;
      }

      // Add text before the link
      if (startIndex > lastIndex) {
        parts.push(text.slice(lastIndex, startIndex));
      }

      // Extract the route and link text
      const routeStart = startIndex + 7; // Length of '<a to={'
      const routeEnd = text.indexOf('}>', routeStart);
      const routeKey = text.slice(routeStart, routeEnd).trim();
      const linkText = text.slice(routeEnd + 2, endIndex);

      // Check if the routeKey contains 'routes' and decide the URL
      let linkUrl = routeKey.includes('routes') ? routes[routeKey.split('.')[1]] : routeKey;

      const isAbsolute = /^https?:\/\//.test(routeKey);
      if (isAbsolute) {
        parts.push(
          <a
            target="_blank"
            href={routeKey}
            key={startIndex}
            style={{ color: theme.colors.secondary }}
          >
            {linkText}
          </a>,
        );
      } else {
        parts.push(
          <MiddysLink pink underlined key={startIndex} to={linkUrl}>
            {linkText}
          </MiddysLink>,
        );
      }

      // Update lastIndex to after the current match
      lastIndex = endIndex + 4; // Length of '</a>'
    }

    return parts;
  };

  const textElements = children.split('\n').map((text: string, index: number) => (
    <React.Fragment key={index}>
      <Box mb={newLineSpace === undefined ? 1 : newLineSpace}>{parseText(text)}</Box>
    </React.Fragment>
  ));

  return <>{textElements}</>;
}

export default FormattedText;
