import { theme } from '../../theme/Theme';
import { makeStyles, withStyles } from '@material-ui/core';
import { pageContainer, root, cardContainer } from '../../theme/GlobalStyles';
import { SIZES } from '../../Constants';
import { ToggleButtonGroup } from '@material-ui/lab';

export const useStyles = makeStyles({
  root: {
    ...root,
    backgroundColor: theme.colors.light,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    ...pageContainer,
    marginTop: 0,
    paddingBottom: 90,
  },
  headerContainer: {
    maxWidth: 450,
    marginTop: -140,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      marginTop: -170,
    },
  },
  headerTitle: {
    fontFamily: theme.font.family2,
    fontWeight: 500,
    fontSize: theme.font.xxxl,
    color: theme.colors.light,
    marginBottom: theme.units.margin * 4,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.xxl,
    },
  },
  headerSubtitle: {
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.l,
    color: theme.colors.light,
    marginBottom: theme.units.margin * 12,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.m,
      marginBottom: theme.units.margin * 8,
    },
  },
  headerButton: {
    position: 'relative',
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.l,
    color: theme.colors.light,
    padding: theme.units.padding * 2,
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 180,
    width: 'max-content',
    backgroundColor: theme.colors.buttonPrimary,
    border: `${theme.colors.buttonPrimary} 2px solid`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.m,
    },
  },
  stepTitle: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.l,
    fontWeight: 500,
    color: theme.colors.darkGrey,
    marginBottom: theme.units.margin * 2,
    marginTop: theme.units.margin * 6,
  },
  stepText: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    color: theme.colors.black60,
    lineHeight: 1.6,
  },
  sectionContainer: {
    marginBottom: 0,
    height: '100%',
  },
  sectionImg: {
    maxWidth: 'unset',
    maxHeight: 350,
    objectPosition: '0 -15px',
  },
});
